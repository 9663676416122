@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: 'Noto Sans KR', 'Noto Sans', sans-serif; }
  *::-webkit-scrollbar {
    background: #f7f7f7;
    width: 5px;
    overflow: auto; }
  *::-webkit-scrollbar-thumb {
    background: #cccccc; }

a {
  color: #222222;
  text-decoration: none; }

li {
  text-decoration: none;
  list-style: none; }

body {
  color: #222222; }

h2 {
  font-size: 2.8125em;
  line-height: 1.2em; }

h3 {
  font-size: 1.375em; }

.default_Visual {
  max-width: 100%;
  height: 80vh;
  max-height: 1000px; }

.default_ExplainText {
  line-height: 1.2em;
  color: #4c4c4c; }

.default_Width {
  max-width: 1320px;
  width: 90%;
  margin: 0 auto; }
  @media screen and (min-width: 1800px) {
    .default_Width {
      max-width: 1600px; } }
  @media screen and (min-width: 360px) and (max-width: 700px) {
    .default_Width {
      width: 95%; } }

.default_small_width {
  max-width: 1032px;
  margin: 0 auto;
  width: 90%;
  padding-top: 130px; }
  .default_small_width h2 {
    font-size: 40px; }
  @media screen and (min-width: 360px) and (max-width: 700px) {
    .default_small_width {
      width: 95%; } }

.default_Etc_Width {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding-top: 130px; }
  .default_Etc_Width h2 {
    font-size: 40px; }
  @media screen and (min-width: 360px) and (max-width: 700px) {
    .default_Etc_Width {
      width: 95%; } }

.default_ContentBox {
  border: 1px solid #cccccc;
  border-radius: 6px; }

.default_button {
  text-align: center;
  border: none;
  background: #eeeeee;
  border-radius: 6px;
  padding: 10px 30px;
  cursor: pointer; }
  .default_button_Point {
    text-align: center;
    border: none;
    background: #004572;
    color: #ffffff;
    border-radius: 6px;
    padding: 10px 30px;
    cursor: pointer; }

.default_defualt_Button_Slide {
  width: 32px;
  height: 32px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-left: 5px;
  background-color: #fff;
  outline: none;
  cursor: pointer; }

.default_modal_animation {
  animation-name: modalMove;
  animation-duration: 0.4s;
  animation-delay: 0s;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: both; }

.default_commonCheckbox input[type="checkbox"] {
  display: none; }

.default_commonCheckbox_agreeLabel {
  cursor: pointer; }

.default_commonCheckbox_agreeBox + .default_commonCheckbox_agreeLabel {
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  margin-left: 5px; }

.default_commonCheckbox_checkBox + .default_commonCheckbox_checkLabel .default_commonCheckbox_checkBoxWrap {
  display: inline-block;
  border: 2px solid #ddd;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer; }
  .default_commonCheckbox_checkBox + .default_commonCheckbox_checkLabel .default_commonCheckbox_checkBoxWrap .fa-check {
    display: none;
    color: #004572; }

.default_commonCheckbox_checkBox:checked + .default_commonCheckbox_checkLabel .default_commonCheckbox_checkBoxWrap .fa-check {
  display: block;
  font-size: 18px;
  position: relative; }

.default_commonCheckbox_checkBox:checked + .default_commonCheckbox_checkLabel .default_commonCheckbox_checkBoxWrap .fa-check:before {
  position: absolute;
  top: -3px;
  left: 0; }

.default_commonRadiobutton input[type="radio"] {
  display: none; }

.default_commonRadiobutton_radioLabel {
  cursor: pointer; }

.default_commonRadiobutton_radioButton + .default_commonRadiobutton_radioLabel {
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  margin-left: 5px; }

.default_commonRadiobutton_radioButton + .default_commonRadiobutton_radioLabel .default_commonRadiobutton_radioButtonWrap {
  display: inline-block;
  border: 2px solid #ddd;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer; }
  .default_commonRadiobutton_radioButton + .default_commonRadiobutton_radioLabel .default_commonRadiobutton_radioButtonWrap .xi-full-moon {
    display: none;
    color: #004572; }

.default_commonRadiobutton_radioButton:checked + .default_commonRadiobutton_radioLabel .default_commonRadiobutton_radioButtonWrap .xi-full-moon {
  display: block;
  font-size: 18px;
  position: relative; }

.default_commonRadiobutton_radioButton:checked + .default_commonRadiobutton_radioLabel .default_commonRadiobutton_radioButtonWrap .xi-full-moon:before {
  position: absolute;
  top: 1px;
  left: 2px; }
  @media screen and (min-width: 360px) and (max-width: 700px) {
    .default_commonRadiobutton_radioButton:checked + .default_commonRadiobutton_radioLabel .default_commonRadiobutton_radioButtonWrap .xi-full-moon:before {
      left: 1px; } }

/* legend,caption,메뉴제목,섹션제목 블라인드 */
legend, caption, .blind {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  width: 1px;
  height: 1px; }

@keyframes modalMove {
  from {
    top: 55%;
    opacity: 0; }
  to {
    top: 40%;
    opacity: 1; } }

.commonRadiobutton input[type="radio"] {
  display: none; }

.commonRadiobutton label {
  cursor: pointer; }

.commonRadiobutton input[type="radio"] + label {
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  margin-left: 5px; }

.commonRadiobutton input[type="radio"] + label span {
  display: inline-block;
  border: 2px solid #ddd;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer; }
  .commonRadiobutton input[type="radio"] + label span .xi-full-moon {
    display: none;
    color: #004572; }

.commonRadiobutton input[type="radio"]:checked + label span .xi-full-moon {
  display: block;
  font-size: 18px;
  position: relative; }

.commonRadiobutton input[type="radio"]:checked + label span .xi-full-moon:before {
  position: absolute;
  top: 1px;
  left: 2px; }
  @media screen and (min-width: 360px) and (max-width: 700px) {
    .commonRadiobutton input[type="radio"]:checked + label span .xi-full-moon:before {
      left: 1px; } }

.commonCheckbox input[type="checkbox"] {
  display: none; }

.commonCheckbox label {
  cursor: pointer; }

.commonCheckbox input[type="checkbox"] + label {
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  margin-left: 5px; }

.commonCheckbox input[type="checkbox"] + label span {
  display: inline-block;
  border: 2px solid #ddd;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer; }
  .commonCheckbox input[type="checkbox"] + label span .fa-check {
    display: none;
    color: #004572; }

.commonCheckbox input[type="checkbox"]:checked + label span .fa-check {
  display: block;
  font-size: 18px;
  position: relative; }

.commonCheckbox input[type="checkbox"]:checked + label span .fa-check:before {
  position: absolute;
  top: -3px;
  left: 0; }

nav {
  margin: 0 !important; }

.write_editor {
  border: 1px solid #ddd;
  height: 500px;
  overflow: auto;
  padding-left: 10px; }

.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
  padding: 0px 4px !important; }

.bg-buddible {
  background: #004572; }

.rotate-infinite {
  animation: rotate 3s linear infinite;
  transform-origin: 50% 50%; }

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
