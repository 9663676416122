@charset "UTF-8";
/*공통*/
* {
  box-sizing: border-box !important; }

.min-vh-100 {
  min-height: calc(100vh - 300px) !important; }

h1, h2, h3, h4, h5, h6 {
  text-overflow: ellipsis; }

input:focus, textarea:focus, select:focus {
  outline: none !important; }

.underline {
  text-decoration: underline; }

.rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.rounded-right-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rounded-bottom-0 {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.rounded-left-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.nav_margin_container {
  padding-top: 120px; }

.loading {
  -webkit-transition-duration: 1s;
  transition: 1s linear infinite;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  animation: loadingSpin 3s infinite; }

@keyframes loadingSpin {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(90deg); }
  100% {
    transform: rotate(0deg); } }

.layer {
  z-index: 3 !important; }

header#plain.sticky {
  padding-bottom: 10px; }

.modal_background {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8); }

.modal_body {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 600px;
  height: 500px;
  background: #ffffff; }

.container {
  max-width: 1600px; }

.cursor_pointer {
  cursor: pointer; }
  .cursor_pointer_bg:hover {
    background: rgba(0, 69, 114, 0.1) !important; }
  .cursor_pointer_bg_light:hover {
    background: rgba(0, 0, 0, 0.1) !important; }

.bg-buddible {
  background: #004572 !important; }

.border-buddible {
  border-color: #004572 !important; }

.text-buddible {
  color: #004572 !important; }

.overflow_scroll_x {
  overflow-x: scroll; }

.overflow_scroll_y {
  overflow-y: scroll; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  white-space: break-spaces;
  word-break: keep-all; }

.ellipsis_1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; }

.ellipsis_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; }

.ellipsis_3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; }

.ellipsis_4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; }

.ellipsis_5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5; }

.font-size-09 {
  font-size: 0.9em !important; }

.font-size-08 {
  font-size: 0.8em !important; }

.font-size-07 {
  font-size: 0.7em !important; }

.font-size-06 {
  font-size: 0.6em !important; }

.font-size-05 {
  font-size: 0.5em !important; }

.font-size-04 {
  font-size: 0.4em !important; }

.font-size-03 {
  font-size: 0.3em !important; }

.font-size-02 {
  font-size: 0.2em !important; }

.font-size-01 {
  font-size: 0.1em !important; }

.rating > i {
  color: #F90; }

/*메인*/
#plain {
  border-bottom: 1px solid #cccccc; }

.categories_grid_img {
  min-height: 250px;
  transition: all 0.4s;
  transform: scale(1.1); }
  @media screen and (max-width: 600px) {
    .categories_grid_img {
      min-height: 150px; } }
  .categories_grid_img:hover {
    transform: scale(1); }

.owl_carousel_img {
  min-height: 225px; }

.owl-item .item {
  color: #111 !important; }

.tour_container .tour_desc {
  padding: 0 15px 10px 15px;
  position: relative; }

.tour_container a {
  color: #111; }

#bestGuide .owl-nav {
  margin-top: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  z-index: 1; }
  #bestGuide .owl-nav > button {
    font-size: 4em;
    color: rgba(255, 255, 255, 0.5); }
    #bestGuide .owl-nav > button:hover {
      color: rgba(0, 0, 0, 0.5);
      background: transparent; }

.owl-nav-custom-center .owl-nav {
  margin-top: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  z-index: 1; }
  .owl-nav-custom-center .owl-nav button {
    font-size: 3em !important;
    color: rgba(255, 255, 255, 0.5) !important; }
    .owl-nav-custom-center .owl-nav button:hover {
      color: rgba(0, 0, 0, 0.5) !important;
      background: transparent !important; }

#guideDetail .owl-nav {
  background: powderblue; }

.custom_date_picker .react-datepicker-wrapper {
  width: 100%;
  height: 100%; }

.custom_date_picker .routeDetailAndPaymentDatePickerBtn {
  cursor: pointer;
  height: 100% !important;
  padding: 20px !important; }

.custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper {
  position: absolute !important;
  left: auto !important;
  top: 0 !important;
  right: 0 !important;
  z-index: 10;
  transform: translate3d(0, 0, 0) !important;
  width: auto;
  height: auto;
  background: #ffffff;
  padding: 20px 40px;
  border-radius: 6px;
  will-change: unset;
  margin: 0;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2); }
  .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker {
    width: auto;
    border: none;
    background: transparent !important; }
    .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation {
      top: 15px; }
    .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container {
      width: calc(50% - 5);
      height: 100%; }
      .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container:nth-of-type(1) {
        margin-right: 10px; }
      .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header {
        background: transparent;
        border: none; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
          font-size: 1.5em; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names {
          font-size: 1.2em; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-name {
          display: inline-block;
          width: 2.5rem;
          line-height: 2.5rem;
          text-align: center;
          margin: 0; }
      .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month {
        font-size: 1em; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day {
          display: inline-block;
          width: 2.5rem;
          line-height: 2.5rem;
          text-align: center;
          margin: 0; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--disabled {
          background: #f5f6f7;
          border-radius: 0; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--today {
          background: transparent;
          color: #222222;
          font-weight: 500; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--in-selecting-range {
          background: transparent;
          color: #222222; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--selected {
          color: #ffffff;
          background: #aaaaaa; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--in-selecting-range-start {
          color: #ffffff;
          background: #aaaaaa; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--in-selecting-range-end {
          background: #aaaaaa; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--in-range {
          background: #cccccc;
          border-radius: 0; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--range-start {
          border: none;
          color: #ffffff;
          background: #666666; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--range-end {
          color: #ffffff;
          background: #666666; }
  .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .routeDetailAndPaymentDatePickerModalHeader {
    text-align: left;
    width: 600px;
    height: 80px; }
  .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .routeDetailAndPaymentDatePickerModalFooter {
    text-align: left; }
    .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .routeDetailAndPaymentDatePickerModalFooter .routeDetailOpenService {
      display: inline-block; }
      .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .routeDetailAndPaymentDatePickerModalFooter .routeDetailOpenService > li {
        padding: 5px 10px;
        margin-right: 5px;
        display: inline-block;
        border: 1px solid #cccccc;
        border-radius: 50px;
        background: #eeeeee; }

/*지도 검색*/
.mapWrapBox {
  height: 100vh;
  padding-top: 9px;
  margin-top: -90px;
  position: sticky;
  top: 0; }

.MapWrap {
  width: 100%;
  height: 100%;
  font-size: 20px;
  text-align: center;
  position: relative; }

.MapWrap > .sbMapbox {
  height: 100%; }

.sbMapbox .googleMap {
  width: 100%;
  height: 100vh; }
  .sbMapbox .googleMap .googleMap_map {
    width: 100%;
    height: 100%; }

.map_content {
  color: #111; }

.map_content_wrap {
  z-index: 10;
  width: 245px; }
  .map_content_wrap > a {
    width: 100%; }
    .map_content_wrap > a > .map_content_img {
      min-height: 140px; }

.gm-style .gm-style-iw-c {
  padding: 0 !important; }

.gm-style-iw-d {
  padding: 0.5rem !important;
  overflow: unset !important; }

.searchByMapMap_Title {
  margin-right: 18px; }

.gm-ui-hover-effect {
  top: 8px !important;
  right: 8px !important;
  width: calc(1rem + 2px) !important;
  height: calc(1rem + 2px) !important;
  border-radius: 8px !important;
  background: rgba(0, 0, 0, 0.6) !important;
  color: #fff !important;
  padding: 2px !important; }

.gm-ui-hover-effect > span {
  margin: 0 !important;
  background-color: #fff !important; }

.styled-select select {
  width: 100% !important; }

.styled-select-common {
  border-right: 0;
  border-left: 0; }

.custom_owl-loaded {
  display: flex !important;
  flex-direction: column-reverse; }

.custom_owl-stage-outer {
  bottom: 0; }

.custom_owl-nav {
  text-align: right;
  position: relative;
  top: 0;
  margin-bottom: 20px; }

.moveBtn {
  background: #ffffff;
  border: 1px solid #dddddd; }

.moveBtn, .moveBtn span {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  border-radius: 15px;
  margin: 0;
  padding: 0; }

.moveBtn:hover {
  opacity: 0.5; }

#tourListWithinHourItem {
  background: rgba(0, 0, 0, 0.2); }

#tourListWithinHourItem:hover {
  background: rgba(0, 0, 0, 0.4); }

.community-main-p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.img_container_community {
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

#community_main_img {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transition: all 0.3s ease-in-out; }

#community_main_img:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2); }

.guideDetailImg {
  transition: 0.2s; }

.guideDetailImg:hover {
  transform: scale(1.2);
  opacity: 0.8; }

.guideDetailBigImg .guideDetailBigImgNav {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center; }

.guideDetailBigImg .moveBtn {
  color: #eeeeee;
  background: transparent;
  border: unset; }
  .guideDetailBigImg .moveBtn span {
    font-size: 50pt; }

.card-header:hover {
  background: rgba(0, 0, 0, 0.1); }

.community-detail-content img {
  cursor: auto !important; }

.hoverOpacity:hover {
  opacity: 0.8; }

.modal-body {
  overflow-y: scroll; }

.max-vh-10 {
  max-height: 10vh; }

.min-vh-10 {
  min-height: 10vh; }

.max-vw-10 {
  max-width: 10vh; }

.max-vw-10 {
  min-width: 10vh; }

.max-vh-20 {
  max-height: 20vh; }

.min-vh-20 {
  min-height: 20vh; }

.max-vw-20 {
  max-width: 20vh; }

.max-vw-20 {
  min-width: 20vh; }

.max-vh-30 {
  max-height: 30vh; }

.min-vh-30 {
  min-height: 30vh; }

.max-vw-30 {
  max-width: 30vh; }

.max-vw-30 {
  min-width: 30vh; }

.max-vh-40 {
  max-height: 40vh; }

.min-vh-40 {
  min-height: 40vh; }

.max-vw-40 {
  max-width: 40vh; }

.max-vw-40 {
  min-width: 40vh; }

.max-vh-50 {
  max-height: 50vh; }

.min-vh-50 {
  min-height: 50vh; }

.max-vw-50 {
  max-width: 50vh; }

.max-vw-50 {
  min-width: 50vh; }

.max-vh-60 {
  max-height: 60vh; }

.min-vh-60 {
  min-height: 60vh; }

.max-vw-60 {
  max-width: 60vh; }

.max-vw-60 {
  min-width: 60vh; }

.max-vh-70 {
  max-height: 70vh; }

.min-vh-70 {
  min-height: 70vh; }

.max-vw-70 {
  max-width: 70vh; }

.max-vw-70 {
  min-width: 70vh; }

.max-vh-80 {
  max-height: 80vh; }

.min-vh-80 {
  min-height: 80vh; }

.max-vw-80 {
  max-width: 80vh; }

.max-vw-80 {
  min-width: 80vh; }

.max-vh-90 {
  max-height: 90vh; }

.min-vh-90 {
  min-height: 90vh; }

.max-vw-90 {
  max-width: 90vh; }

.max-vw-90 {
  min-width: 90vh; }

.max-vh-100 {
  max-height: 100vh; }

.min-vh-100 {
  min-height: 100vh; }

.max-vw-100 {
  max-width: 100vh; }

.max-vw-100 {
  min-width: 100vh; }

.min-width-10 {
  min-width: 10px; }

.min-height-10 {
  min-height: 10px; }

.min-width-20 {
  min-width: 20px; }

.min-height-20 {
  min-height: 20px; }

.min-width-30 {
  min-width: 30px; }

.min-height-30 {
  min-height: 30px; }

.min-width-40 {
  min-width: 40px; }

.min-height-40 {
  min-height: 40px; }

.min-width-50 {
  min-width: 50px; }

.min-height-50 {
  min-height: 50px; }

.min-width-60 {
  min-width: 60px; }

.min-height-60 {
  min-height: 60px; }

.min-width-70 {
  min-width: 70px; }

.min-height-70 {
  min-height: 70px; }

.min-width-80 {
  min-width: 80px; }

.min-height-80 {
  min-height: 80px; }

.min-width-90 {
  min-width: 90px; }

.min-height-90 {
  min-height: 90px; }

.min-width-100 {
  min-width: 100px; }

.min-height-100 {
  min-height: 100px; }

.min-width-110 {
  min-width: 110px; }

.min-height-110 {
  min-height: 110px; }

.min-width-120 {
  min-width: 120px; }

.min-height-120 {
  min-height: 120px; }

.min-width-130 {
  min-width: 130px; }

.min-height-130 {
  min-height: 130px; }

.min-width-140 {
  min-width: 140px; }

.min-height-140 {
  min-height: 140px; }

.min-width-150 {
  min-width: 150px; }

.min-height-150 {
  min-height: 150px; }

.min-width-160 {
  min-width: 160px; }

.min-height-160 {
  min-height: 160px; }

.min-width-170 {
  min-width: 170px; }

.min-height-170 {
  min-height: 170px; }

.min-width-180 {
  min-width: 180px; }

.min-height-180 {
  min-height: 180px; }

.min-width-190 {
  min-width: 190px; }

.min-height-190 {
  min-height: 190px; }

.min-width-200 {
  min-width: 200px; }

.min-height-200 {
  min-height: 200px; }

.min-width-210 {
  min-width: 210px; }

.min-height-210 {
  min-height: 210px; }

.min-width-220 {
  min-width: 220px; }

.min-height-220 {
  min-height: 220px; }

.min-width-230 {
  min-width: 230px; }

.min-height-230 {
  min-height: 230px; }

.min-width-240 {
  min-width: 240px; }

.min-height-240 {
  min-height: 240px; }

.min-width-250 {
  min-width: 250px; }

.min-height-250 {
  min-height: 250px; }

.min-width-260 {
  min-width: 260px; }

.min-height-260 {
  min-height: 260px; }

.min-width-270 {
  min-width: 270px; }

.min-height-270 {
  min-height: 270px; }

.min-width-280 {
  min-width: 280px; }

.min-height-280 {
  min-height: 280px; }

.min-width-290 {
  min-width: 290px; }

.min-height-290 {
  min-height: 290px; }

.min-width-300 {
  min-width: 300px; }

.min-height-300 {
  min-height: 300px; }

.min-width-310 {
  min-width: 310px; }

.min-height-310 {
  min-height: 310px; }

.min-width-320 {
  min-width: 320px; }

.min-height-320 {
  min-height: 320px; }

.min-width-330 {
  min-width: 330px; }

.min-height-330 {
  min-height: 330px; }

.min-width-340 {
  min-width: 340px; }

.min-height-340 {
  min-height: 340px; }

.min-width-350 {
  min-width: 350px; }

.min-height-350 {
  min-height: 350px; }

.min-width-360 {
  min-width: 360px; }

.min-height-360 {
  min-height: 360px; }

.min-width-370 {
  min-width: 370px; }

.min-height-370 {
  min-height: 370px; }

.min-width-380 {
  min-width: 380px; }

.min-height-380 {
  min-height: 380px; }

.min-width-390 {
  min-width: 390px; }

.min-height-390 {
  min-height: 390px; }

.min-width-400 {
  min-width: 400px; }

.min-height-400 {
  min-height: 400px; }

.min-width-410 {
  min-width: 410px; }

.min-height-410 {
  min-height: 410px; }

.min-width-420 {
  min-width: 420px; }

.min-height-420 {
  min-height: 420px; }

.min-width-430 {
  min-width: 430px; }

.min-height-430 {
  min-height: 430px; }

.min-width-440 {
  min-width: 440px; }

.min-height-440 {
  min-height: 440px; }

.min-width-450 {
  min-width: 450px; }

.min-height-450 {
  min-height: 450px; }

.min-width-460 {
  min-width: 460px; }

.min-height-460 {
  min-height: 460px; }

.min-width-470 {
  min-width: 470px; }

.min-height-470 {
  min-height: 470px; }

.min-width-480 {
  min-width: 480px; }

.min-height-480 {
  min-height: 480px; }

.min-width-490 {
  min-width: 490px; }

.min-height-490 {
  min-height: 490px; }

.min-width-500 {
  min-width: 500px; }

.min-height-500 {
  min-height: 500px; }

.min-width-510 {
  min-width: 510px; }

.min-height-510 {
  min-height: 510px; }

.min-width-520 {
  min-width: 520px; }

.min-height-520 {
  min-height: 520px; }

.min-width-530 {
  min-width: 530px; }

.min-height-530 {
  min-height: 530px; }

.min-width-540 {
  min-width: 540px; }

.min-height-540 {
  min-height: 540px; }

.min-width-550 {
  min-width: 550px; }

.min-height-550 {
  min-height: 550px; }

.min-width-560 {
  min-width: 560px; }

.min-height-560 {
  min-height: 560px; }

.min-width-570 {
  min-width: 570px; }

.min-height-570 {
  min-height: 570px; }

.min-width-580 {
  min-width: 580px; }

.min-height-580 {
  min-height: 580px; }

.min-width-590 {
  min-width: 590px; }

.min-height-590 {
  min-height: 590px; }

.min-width-600 {
  min-width: 600px; }

.min-height-600 {
  min-height: 600px; }

.min-width-610 {
  min-width: 610px; }

.min-height-610 {
  min-height: 610px; }

.min-width-620 {
  min-width: 620px; }

.min-height-620 {
  min-height: 620px; }

.min-width-630 {
  min-width: 630px; }

.min-height-630 {
  min-height: 630px; }

.min-width-640 {
  min-width: 640px; }

.min-height-640 {
  min-height: 640px; }

.min-width-650 {
  min-width: 650px; }

.min-height-650 {
  min-height: 650px; }

.min-width-660 {
  min-width: 660px; }

.min-height-660 {
  min-height: 660px; }

.min-width-670 {
  min-width: 670px; }

.min-height-670 {
  min-height: 670px; }

.min-width-680 {
  min-width: 680px; }

.min-height-680 {
  min-height: 680px; }

.min-width-690 {
  min-width: 690px; }

.min-height-690 {
  min-height: 690px; }

.min-width-700 {
  min-width: 700px; }

.min-height-700 {
  min-height: 700px; }

.min-width-710 {
  min-width: 710px; }

.min-height-710 {
  min-height: 710px; }

.min-width-720 {
  min-width: 720px; }

.min-height-720 {
  min-height: 720px; }

.min-width-730 {
  min-width: 730px; }

.min-height-730 {
  min-height: 730px; }

.min-width-740 {
  min-width: 740px; }

.min-height-740 {
  min-height: 740px; }

.min-width-750 {
  min-width: 750px; }

.min-height-750 {
  min-height: 750px; }

.min-width-760 {
  min-width: 760px; }

.min-height-760 {
  min-height: 760px; }

.min-width-770 {
  min-width: 770px; }

.min-height-770 {
  min-height: 770px; }

.min-width-780 {
  min-width: 780px; }

.min-height-780 {
  min-height: 780px; }

.min-width-790 {
  min-width: 790px; }

.min-height-790 {
  min-height: 790px; }

.min-width-800 {
  min-width: 800px; }

.min-height-800 {
  min-height: 800px; }

.min-width-810 {
  min-width: 810px; }

.min-height-810 {
  min-height: 810px; }

.min-width-820 {
  min-width: 820px; }

.min-height-820 {
  min-height: 820px; }

.min-width-830 {
  min-width: 830px; }

.min-height-830 {
  min-height: 830px; }

.min-width-840 {
  min-width: 840px; }

.min-height-840 {
  min-height: 840px; }

.min-width-850 {
  min-width: 850px; }

.min-height-850 {
  min-height: 850px; }

.min-width-860 {
  min-width: 860px; }

.min-height-860 {
  min-height: 860px; }

.min-width-870 {
  min-width: 870px; }

.min-height-870 {
  min-height: 870px; }

.min-width-880 {
  min-width: 880px; }

.min-height-880 {
  min-height: 880px; }

.min-width-890 {
  min-width: 890px; }

.min-height-890 {
  min-height: 890px; }

.min-width-900 {
  min-width: 900px; }

.min-height-900 {
  min-height: 900px; }

.min-width-910 {
  min-width: 910px; }

.min-height-910 {
  min-height: 910px; }

.min-width-920 {
  min-width: 920px; }

.min-height-920 {
  min-height: 920px; }

.min-width-930 {
  min-width: 930px; }

.min-height-930 {
  min-height: 930px; }

.min-width-940 {
  min-width: 940px; }

.min-height-940 {
  min-height: 940px; }

.min-width-950 {
  min-width: 950px; }

.min-height-950 {
  min-height: 950px; }

.min-width-960 {
  min-width: 960px; }

.min-height-960 {
  min-height: 960px; }

.min-width-970 {
  min-width: 970px; }

.min-height-970 {
  min-height: 970px; }

.min-width-980 {
  min-width: 980px; }

.min-height-980 {
  min-height: 980px; }

.min-width-990 {
  min-width: 990px; }

.min-height-990 {
  min-height: 990px; }

.text-shadow-sm {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25); }

.text-shadow-md {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }

.custom-default-dropdown-select .react-dropdown-select-input {
  flex-grow: 1; }

.main .parallax-content-2 .theiaStickySidebar #search_container {
  z-index: revert; }

.ribbon_3 {
  z-index: 2; }

.rounded-xl {
  border-radius: .5rem; }

.rotate-10 {
  transform: rotate(10deg) !important; }

.rotate-20 {
  transform: rotate(20deg) !important; }

.rotate-30 {
  transform: rotate(30deg) !important; }

.rotate-40 {
  transform: rotate(40deg) !important; }

.rotate-50 {
  transform: rotate(50deg) !important; }

.rotate-60 {
  transform: rotate(60deg) !important; }

.rotate-70 {
  transform: rotate(70deg) !important; }

.rotate-80 {
  transform: rotate(80deg) !important; }

.rotate-90 {
  transform: rotate(90deg) !important; }

.rotate-100 {
  transform: rotate(100deg) !important; }

.rotate-110 {
  transform: rotate(110deg) !important; }

.rotate-120 {
  transform: rotate(120deg) !important; }

.rotate-130 {
  transform: rotate(130deg) !important; }

.rotate-140 {
  transform: rotate(140deg) !important; }

.rotate-150 {
  transform: rotate(150deg) !important; }

.rotate-160 {
  transform: rotate(160deg) !important; }

.rotate-170 {
  transform: rotate(170deg) !important; }

.rotate-180 {
  transform: rotate(180deg) !important; }

.rotate-190 {
  transform: rotate(190deg) !important; }

.rotate-200 {
  transform: rotate(200deg) !important; }

.rotate-210 {
  transform: rotate(210deg) !important; }

.rotate-220 {
  transform: rotate(220deg) !important; }

.rotate-230 {
  transform: rotate(230deg) !important; }

.rotate-240 {
  transform: rotate(240deg) !important; }

.rotate-250 {
  transform: rotate(250deg) !important; }

.rotate-260 {
  transform: rotate(260deg) !important; }

.rotate-270 {
  transform: rotate(270deg) !important; }

.rotate-280 {
  transform: rotate(280deg) !important; }

.rotate-290 {
  transform: rotate(290deg) !important; }

.rotate-300 {
  transform: rotate(300deg) !important; }

.rotate-310 {
  transform: rotate(310deg) !important; }

.rotate-320 {
  transform: rotate(320deg) !important; }

.rotate-330 {
  transform: rotate(330deg) !important; }

.rotate-340 {
  transform: rotate(340deg) !important; }

.rotate-350 {
  transform: rotate(350deg) !important; }

.quill-wrap img {
  max-width: 100% !important; }

.css-93ilmd-DropDown {
  top: calc(100% + 3px) !important;
  width: 100% !important;
  box-shadow: none !important; }

.css-4rg3ly-InputComponent {
  flex-grow: 1; }

.main {
  z-index: 3 !important; }

.modal-backdrop {
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 4 !important; }

.modal {
  z-index: 5 !important; }

.modal-content {
  border: none !important; }

.react-datepicker__navigation {
  background: #fff; }

.react-datepicker__navigation--previous {
  content: '&lt'; }

.react-datepicker__navigation--previous {
  content: '&rt'; }

.search-overlay-menu {
  background: rgba(0, 0, 0, 0.6); }
  .search-overlay-menu #search-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 1000px;
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s; }
    .search-overlay-menu #search-content .react-dropdown-select-content {
      align-items: center; }
    .search-overlay-menu #search-content .numbers-row > div, .search-overlay-menu #search-content input {
      height: 36px !important; }
  .search-overlay-menu .css-wmw4vi-ReactDropdownSelect {
    padding: 0; }
  .search-overlay-menu .css-1ngods0-InputComponent {
    margin-left: 0; }
  .search-overlay-menu .react-datepicker__tab-loop .react-datepicker-popper {
    top: calc(100%) !important;
    min-width: 330px !important;
    padding: 20px; }
    .search-overlay-menu .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker__day--outside-month {
      color: #004572; }
    .search-overlay-menu .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker__day--disabled {
      color: #aaaaaa; }

.avata_pic {
  object-fit: cover; }

.chat-header {
  height: 60px !important; }

.chat-footer {
  height: 60px !important; }

.chat-list-center {
  height: calc(100vh - 120px) !important; }

.chat-list-end {
  height: calc(100vh - 60px) !important; }

.chat-msg {
  max-width: 75%; }

.chat-msg-sender {
  border-radius: 0.5rem 0.5rem 0 0.5rem;
  background: #dbf4fe; }

.chat-msg-receiver {
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  background: #f8f9fa; }

.square-10 {
  width: 10px !important;
  height: 10px !important; }

.square-20 {
  width: 20px !important;
  height: 20px !important; }

.square-30 {
  width: 30px !important;
  height: 30px !important; }

.square-40 {
  width: 40px !important;
  height: 40px !important; }

.square-50 {
  width: 50px !important;
  height: 50px !important; }

.square-60 {
  width: 60px !important;
  height: 60px !important; }

.square-70 {
  width: 70px !important;
  height: 70px !important; }

.square-80 {
  width: 80px !important;
  height: 80px !important; }

.square-90 {
  width: 90px !important;
  height: 90px !important; }

.square-100 {
  width: 100px !important;
  height: 100px !important; }

.height-10 {
  height: 10px !important; }

.height-20 {
  height: 20px !important; }

.height-30 {
  height: 30px !important; }

.height-40 {
  height: 40px !important; }

.height-50 {
  height: 50px !important; }

.height-60 {
  height: 60px !important; }

.height-70 {
  height: 70px !important; }

.height-80 {
  height: 80px !important; }

.height-90 {
  height: 90px !important; }

.height-100 {
  height: 100px !important; }

.child-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.erp .ql-snow {
  border: none; }

.erp .ql-editor {
  border-top: 1px solid #dee2e6; }
