@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
.commonModal {
  height: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 99999999999999;
  width: 100%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 6px; }
  @media screen and (min-width: 360px) and (max-width: 700px) {
    .commonModal {
      overflow-y: scroll;
      width: 95%; } }
  .commonModal .commonModalTit {
    line-height: 70px;
    margin: 0 20px;
    position: relative;
    border-bottom: 1px solid #eee; }
    .commonModal .commonModalTit > h3 {
      font-size: 1.125em;
      display: inline-block; }
    .commonModal .commonModalTit > .fa-times {
      position: absolute;
      right: 5px;
      top: 22px;
      line-height: 36px;
      font-size: 1.375em;
      cursor: pointer;
      width: 40px;
      height: 40px;
      text-align: center; }
      .commonModal .commonModalTit > .fa-times:hover {
        width: 40px;
        height: 40px;
        background-color: #eee;
        border-radius: 50%; }
  .commonModal .commonModalText {
    padding: 20px; }
    .commonModal .commonModalText > h4 {
      font-size: 1.375em; }
    .commonModal .commonModalText > p {
      padding-top: 10px;
      font-size: 1.125em; }
  .commonModal .commonModalInputTextWrap {
    margin: 0 20px; }
    .commonModal .commonModalInputTextWrap .commonModalInput {
      border: 1px solid #ccc;
      border-radius: 6px;
      padding: 14px 14px;
      font-size: 1.125em;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 10px; }
    .commonModal .commonModalInputTextWrap .commonModalInputText {
      color: red;
      padding-bottom: 10px; }
  .commonModal .commonModalBtnWrap {
    padding: 0 20px 20px 20px;
    float: right; }
    .commonModal .commonModalBtnWrap .commonModalBtnCancel {
      margin-left: 10px; }

.commonModal_layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999; }

.loadingWrap {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  color: #ffffff; }
  .loadingWrap .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center; }
    .loadingWrap .loading > i {
      -webkit-animation: spin 3s linear 0.5s infinite;
              animation: spin 3s linear 0.5s infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@charset "UTF-8";
html {
  position: relative;
  min-height: 100%; }

body {
  height: 100%; }

a:focus {
  outline: 0; }

#wrapper {
  display: -webkit-flex;
  display: flex;
  font-size: 0.875rem;
  /*

.input-group-append span {
  -webkit-box-shadow: 0 .125rem .25rem 0 rgba(58, 59, 69, .2) !important;
  box-shadow: 0 .125rem .25rem 0 rgba(58, 59, 69, .2) !important;
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5
}
*/ }
  #wrapper .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "파일첨부"; }
  #wrapper .btn {
    font-size: 0.875rem; }
  #wrapper .form-control {
    font-size: 0.875rem; }
  #wrapper .input-group-text {
    font-size: 0.875rem; }
  #wrapper .form-select {
    font-size: 0.875rem; }
  #wrapper #content-wrapper {
    background-color: #fafdfb;
    width: 100%;
    overflow-x: hidden; }
  #wrapper #content-wrapper #content {
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto; }
  #wrapper .container,
  #wrapper .container-fluid,
  #wrapper .container-login {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  #wrapper .scroll-to-top {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    display: none;
    width: 2.75rem;
    height: 2.75rem;
    text-align: center;
    color: #fff;
    background: rgba(90, 92, 105, 0.5);
    line-height: 46px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out; }
  #wrapper .scroll-to-top:focus,
  #wrapper .scroll-to-top:hover {
    color: #fff;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out; }
  #wrapper .scroll-to-top:hover {
    background: #5a5c69;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out; }
  #wrapper .scroll-to-top i {
    font-weight: 800; }

@-webkit-keyframes growIn {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes growIn {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }
  #wrapper .animated--grow-in,
  #wrapper .sidebar .nav-item .collapse {
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    /*-webkit-animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
  animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1)*/ }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  #wrapper .animated--fade-in {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    /*-webkit-animation-timing-function: opacity cubic-bezier(0, 1, .4, 1);
  animation-timing-function: opacity cubic-bezier(0, 1, .4, 1)*/ }
  #wrapper .bg-gradient-primary {
    background-color: #6777ef;
    background-image: linear-gradient(180deg, #6777ef 10%, #303f9f 100%);
    background-size: cover; }
  #wrapper .bg-gradient-secondary {
    background-color: #757575;
    background-image: linear-gradient(180deg, #757575 10%, #60616f 100%);
    background-size: cover; }
  #wrapper .bg-gradient-success {
    background-color: #66bb6a;
    background-image: linear-gradient(180deg, #66bb6a 10%, #13855c 100%);
    background-size: cover; }
  #wrapper .bg-gradient-info {
    background-color: #3abaf4;
    background-image: linear-gradient(180deg, #3abaf4 10%, #258391 100%);
    background-size: cover; }
  #wrapper .bg-gradient-warning {
    background-color: #ffa426;
    background-image: linear-gradient(180deg, #f6c23e 10%, #ffa426 100%);
    background-size: cover; }
  #wrapper .bg-gradient-danger {
    background-color: #fc544b;
    background-image: linear-gradient(180deg, #fc544b 10%, #be2617 100%);
    background-size: cover; }
  #wrapper .bg-gradient-light {
    background-color: #e3eaef;
    background-image: linear-gradient(180deg, #e3eaef 10%, #c2cbe5 100%);
    background-size: cover; }
  #wrapper .bg-gradient-dark {
    background-color: #212121;
    background-image: linear-gradient(180deg, #212121 10%, #373840 100%);
    background-size: cover; }
  #wrapper .bg-gradient-default {
    background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important; }
  #wrapper .bg-gradient-login {
    background-color: #f8f9fc;
    background-size: cover; }
  #wrapper .bg-gray-100 {
    background-color: #f8f9fc !important; }
  #wrapper .bg-gray-200 {
    background-color: #eaecf4 !important; }
  #wrapper .bg-gray-300 {
    background-color: #dddfeb !important; }
  #wrapper .bg-gray-400 {
    background-color: #d1d3e2 !important; }
  #wrapper .bg-gray-500 {
    background-color: #b7b9cc !important; }
  #wrapper .bg-gray-600 {
    background-color: #858796 !important; }
  #wrapper .bg-gray-700 {
    background-color: #6e707e !important; }
  #wrapper .bg-gray-800 {
    background-color: #5a5c69 !important; }
  #wrapper .bg-gray-900 {
    background-color: #3a3b45 !important; }
  #wrapper .o-hidden {
    overflow: hidden !important; }
  #wrapper .text-xs {
    font-size: .7rem; }
  #wrapper .text-lg {
    font-size: 1.2rem; }
  #wrapper .text-gray-100 {
    color: #f8f9fc !important; }
  #wrapper .text-gray-200 {
    color: #eaecf4 !important; }
  #wrapper .text-gray-300 {
    color: #dddfeb !important; }
  #wrapper .text-gray-400 {
    color: #d1d3e2 !important; }
  #wrapper .text-gray-500 {
    color: #b7b9cc !important; }
  #wrapper .text-gray-600 {
    color: #858796 !important; }
  #wrapper .text-gray-700 {
    color: #6e707e !important; }
  #wrapper .text-gray-800 {
    color: #5a5c69 !important; }
  #wrapper .text-gray-900 {
    color: #3a3b45 !important; }
  #wrapper .icon-circle {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  #wrapper .border-left-primary {
    border-left: .25rem solid #3f51b5 !important; }
  #wrapper .border-bottom-primary {
    border-bottom: .25rem solid #3f51b5 !important; }
  #wrapper .border-left-secondary {
    border-left: .25rem solid #757575 !important; }
  #wrapper .border-bottom-secondary {
    border-bottom: .25rem solid #757575 !important; }
  #wrapper .border-left-success {
    border-left: .25rem solid #66bb6a !important; }
  #wrapper .border-bottom-success {
    border-bottom: .25rem solid #66bb6a !important; }
  #wrapper .border-left-info {
    border-left: .25rem solid #4dd0e1 !important; }
  #wrapper .border-bottom-info {
    border-bottom: .25rem solid #4dd0e1 !important; }
  #wrapper .border-left-warning {
    border-left: .25rem solid #ffeb3b !important; }
  #wrapper .border-bottom-warning {
    border-bottom: .25rem solid #ffeb3b !important; }
  #wrapper .border-left-danger {
    border-left: .25rem solid #ef5350 !important; }
  #wrapper .border-bottom-danger {
    border-bottom: .25rem solid #ef5350 !important; }
  #wrapper .border-left-light {
    border-left: .25rem solid #f8f9fc !important; }
  #wrapper .border-bottom-light {
    border-bottom: .25rem solid #f8f9fc !important; }
  #wrapper .border-left-dark {
    border-left: .25rem solid #5a5c69 !important; }
  #wrapper .border-bottom-dark {
    border-bottom: .25rem solid #5a5c69 !important; }
  #wrapper .progress-sm {
    height: .5rem; }
  #wrapper .rotate-15 {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  #wrapper .rotate-n-15 {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg); }
  #wrapper .dropdown .dropdown-menu {
    font-size: .85rem;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important; }
  #wrapper .dropdown .dropdown-menu .dropdown-header {
    font-weight: 600;
    font-size: .65rem;
    color: #b7b9cc; }
  #wrapper .dropdown.no-arrow .dropdown-toggle::after {
    display: none; }
  #wrapper .sidebar .nav-item.dropdown .dropdown-toggle::after,
  #wrapper .topbar .nav-item.dropdown .dropdown-toggle::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f105'; }
  #wrapper .sidebar .nav-item.dropdown.show .dropdown-toggle::after,
  #wrapper .topbar .nav-item.dropdown.show .dropdown-toggle::after {
    content: '\f107'; }
  #wrapper .sidebar .nav-item .nav-link,
  #wrapper .topbar .nav-item .nav-link {
    position: relative; }
  #wrapper .sidebar .nav-item .nav-link .badge-counter,
  #wrapper .topbar .nav-item .nav-link .badge-counter {
    position: absolute;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-transform-origin: top right;
    transform-origin: top right;
    right: .25rem;
    margin-top: -.25rem; }
  #wrapper .sidebar .nav-item .nav-link .img-profile,
  #wrapper .topbar .nav-item .nav-link .img-profile {
    height: 2rem;
    width: 2rem;
    border: 1px solid #fafafa; }
  #wrapper .topbar {
    height: 4.375rem;
    z-index: 2;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important; }
  #wrapper .topbar #sidebarToggleTop {
    height: 2.5rem;
    width: 2.5rem;
    box-shadow: 0 0 !important; }
  #wrapper .topbar #sidebarToggleTop:hover {
    background-color: #fafafa; }
  #wrapper .topbar #sidebarToggleTop:active {
    background-color: #dddfeb; }
  #wrapper .topbar .navbar-search {
    width: 25rem; }
  #wrapper .topbar .navbar-search input {
    font-size: .85rem; }
  #wrapper .topbar .topbar-divider {
    width: 0;
    border-right: 1px solid #e3e6f0;
    height: calc(4.375rem - 2rem);
    margin: auto 1rem; }
  #wrapper .topbar .nav-item .nav-link {
    height: 4.375rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 .75rem; }
  #wrapper .topbar .nav-item .nav-link:focus {
    outline: 0; }
  #wrapper .topbar .nav-item:focus {
    outline: 0; }
  #wrapper .topbar .dropdown {
    position: static; }
  #wrapper .topbar .dropdown .dropdown-menu {
    width: calc(100% - 1.5rem);
    right: .75rem; }
  #wrapper .topbar .dropdown-list {
    padding: 0;
    border: none;
    overflow: hidden; }
  #wrapper .topbar .dropdown-list .dropdown-header {
    background-color: #3f51b5;
    border: 1px solid #3f51b5;
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #fff; }
  #wrapper .topbar .dropdown-list .dropdown-item {
    white-space: normal;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-left: 1px solid #e3e6f0;
    border-right: 1px solid #e3e6f0;
    border-bottom: 1px solid #e3e6f0;
    line-height: 1.3rem; }
  #wrapper .topbar .dropdown-list .dropdown-item .dropdown-list-image {
    position: relative;
    height: 2.5rem;
    width: 2.5rem; }
  #wrapper .topbar .dropdown-list .dropdown-item .dropdown-list-image img {
    height: 2.5rem;
    width: 2.5rem; }
  #wrapper .topbar .dropdown-list .dropdown-item .dropdown-list-image .status-indicator {
    background-color: #eaecf4;
    height: .75rem;
    width: .75rem;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    border: .125rem solid #fff; }
  #wrapper .topbar .dropdown-list .dropdown-item .text-truncate {
    max-width: 10rem; }
  #wrapper .topbar .dropdown-list .dropdown-item:active {
    background-color: #eaecf4;
    color: #3a3b45; }
  @media (min-width: 576px) {
    #wrapper .topbar .dropdown {
      position: relative; }
    #wrapper .topbar .dropdown .dropdown-menu {
      width: auto;
      right: 0; }
    #wrapper .topbar .dropdown-list {
      width: 20rem !important; }
    #wrapper .topbar .dropdown-list .dropdown-item .text-truncate {
      max-width: 13.375rem; } }
  #wrapper .topbar.navbar-light .navbar-nav .nav-item .nav-link {
    color: #fafafa;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out; }
  #wrapper .topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
    color: #e0e0e0;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out; }
  #wrapper .topbar.navbar-light .navbar-nav .nav-item .nav-link:active {
    color: #858796; }
  #wrapper .bg-navbar {
    background-color: #6777ef; }
  #wrapper .sidebar {
    min-height: 100vh;
    width: 14rem !important;
    background-color: #fff !important;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
    z-index: 0; }
  #wrapper .sidebar .nav-item {
    position: relative;
    margin-bottom: -15px; }
  #wrapper .sidebar .nav-item:last-child {
    margin-bottom: 1rem; }
  #wrapper .sidebar .nav-item .nav-link {
    display: block;
    width: 100%;
    text-align: left;
    padding: 1rem; }
  #wrapper .sidebar .nav-item .nav-link i {
    font-size: .85rem;
    margin-right: .25rem; }
  #wrapper .sidebar .nav-item .nav-link span {
    font-size: .85rem;
    display: inline; }
  #wrapper .sidebar .nav-item.active .nav-link {
    font-weight: 800; }
  #wrapper .sidebar .nav-item .nav-link[data-toggle=collapse]::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f107'; }
  #wrapper .sidebar .nav-item .nav-link[data-toggle=collapse].collapsed::after {
    content: '\f105'; }
  #wrapper .sidebar .nav-item .collapse {
    position: relative;
    left: 0;
    z-index: 1;
    top: 0;
    -webkit-animation: none;
    animation: none; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner {
    border-radius: .35rem;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out; }
  #wrapper .sidebar .nav-item .collapsing {
    display: block;
    transition: height .3s ease; }
  #wrapper .sidebar .nav-item .collapse,
  #wrapper .sidebar .nav-item .collapsing {
    margin: 0 1rem; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner,
  #wrapper .sidebar .nav-item .collapsing .collapse-inner {
    padding: .5rem 0;
    min-width: 10rem;
    font-size: .85rem;
    margin: 0 0 1rem 0; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner .collapse-header,
  #wrapper .sidebar .nav-item .collapsing .collapse-inner .collapse-header {
    margin: 0;
    white-space: nowrap;
    padding: .5rem 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .65rem;
    color: #b7b9cc; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner .collapse-item,
  #wrapper .sidebar .nav-item .collapsing .collapse-inner .collapse-item {
    padding: .5rem 1rem;
    margin: 0 .5rem;
    display: block;
    color: #3a3b45;
    text-decoration: none;
    border-radius: .35rem;
    white-space: nowrap; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
  #wrapper .sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
    background-color: #eaecf4;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner .collapse-item:active,
  #wrapper .sidebar .nav-item .collapsing .collapse-inner .collapse-item:active {
    background-color: #dddfeb; }
  #wrapper .sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
  #wrapper .sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
    color: #3a3b45;
    font-weight: 800; }
  #wrapper .sidebar #sidebarToggle {
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
    cursor: pointer; }
  #wrapper .sidebar #sidebarToggle::after {
    font-weight: 900;
    content: '\f104';
    margin-right: .1rem; }
  #wrapper .sidebar #sidebarToggle:hover {
    text-decoration: none; }
  #wrapper .sidebar #sidebarToggle:focus {
    outline: 0; }
  #wrapper .sidebar .sidebar-brand {
    height: 4.375rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    padding: 1.5rem 1rem;
    text-align: center;
    letter-spacing: .05rem;
    z-index: 1; }
  #wrapper .sidebar .sidebar-brand .sidebar-brand-icon img {
    max-height: 2.3rem; }
  #wrapper .sidebar hr.sidebar-divider {
    margin: 1rem 0 1rem 0; }
  #wrapper .sidebar .sidebar-heading {
    text-align: left;
    padding: 0 1rem;
    font-weight: 600;
    font-size: .75rem;
    letter-spacing: .13rem; }
  #wrapper .sidebar .version {
    text-align: left;
    font-size: .6rem;
    padding: 0 1rem;
    color: #b7b9cc;
    letter-spacing: .030rem; }
  #wrapper .sidebar .sidebar-brand .sidebar-brand-text {
    display: inline; }
  #wrapper .sidebar.toggled {
    overflow: visible;
    width: 6.5rem !important;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    z-index: 1; }
  #wrapper .sidebar.toggled .nav-item {
    margin-bottom: 0; }
  #wrapper .sidebar.toggled hr.sidebar-divider {
    margin: 0 0 1rem 0; }
  #wrapper .sidebar.toggled #sidebarToggle::after {
    content: '\f105';
    margin-left: .25rem; }
  #wrapper .sidebar.toggled .nav-item .collapse {
    position: absolute;
    left: calc(6.5rem + 1.5rem / 2);
    top: 2px;
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    /*-webkit-animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
  animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1)*/ }
  #wrapper .sidebar.toggled .nav-item .collapse,
  #wrapper .sidebar.toggled .nav-item .collapsing {
    margin: 0; }
  #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: .35rem; }
  #wrapper .sidebar.toggled .nav-item .collapsing {
    display: none;
    transition: none; }
  #wrapper .sidebar.toggled .nav-item:last-child {
    margin-bottom: 1rem; }
  #wrapper .sidebar.toggled .nav-item .nav-link {
    text-align: center;
    padding: .75rem 1rem;
    width: 6.5rem; }
  #wrapper .sidebar.toggled .nav-item .nav-link {
    background-color: transparent !important; }
  #wrapper .sidebar.toggled .sidebar-heading {
    display: none; }
  #wrapper .sidebar.toggled .nav-item .nav-link span {
    display: none; }
  #wrapper .sidebar.toggled .nav-item .nav-link i {
    float: left;
    text-align: center;
    font-size: 1rem;
    margin: 0 0 .7rem 1rem; }
  #wrapper .sidebar.toggled .sidebar-brand .sidebar-brand-text {
    display: none; }
  #wrapper .sidebar.toggled .nav-item .nav-link[data-toggle=collapse]::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f107'; }
  #wrapper .sidebar.toggled .nav-item .nav-link[data-toggle=collapse].collapsed::after {
    content: '\f105'; }
  #wrapper .login-form {
    padding: 3rem !important; }
  @media (max-width: 768px) {
    #wrapper #container-wrapper {
      min-width: 100vw; }
    #wrapper .sidebar.toggled {
      width: 100vw !important;
      transition: all .3s ease-in-out;
      -webkit-transition: all .3s ease-in-out; }
    #wrapper .sidebar.toggled .nav-item {
      margin-left: -1rem;
      position: relative;
      margin-bottom: -15px; }
    #wrapper .sidebar.toggled .nav-item .nav-link {
      display: block;
      width: 100%;
      text-align: left;
      padding: 1rem; }
    #wrapper .sidebar.toggled .nav-item .nav-link i {
      font-size: .85rem;
      margin-top: .4rem;
      margin-right: .5rem; }
    #wrapper .sidebar.toggled .nav-item .nav-link span {
      font-size: .85rem;
      display: inline; }
    #wrapper .sidebar.toggled .nav-item.active .nav-link {
      font-weight: 700; }
    #wrapper .sidebar.toggled .nav-item .nav-link[data-toggle=collapse]::after {
      width: 1rem;
      text-align: center;
      float: right;
      vertical-align: 0;
      border: 0;
      font-weight: 900;
      content: '\f107'; }
    #wrapper .sidebar.toggled .nav-item .nav-link[data-toggle=collapse].collapsed::after {
      content: '\f105'; }
    #wrapper .sidebar.toggled .nav-item .collapse {
      position: relative;
      padding-left: 1rem;
      left: 0;
      z-index: 1;
      top: 0;
      transition: height .15s ease; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner {
      border-radius: .35rem;
      box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15); }
    #wrapper .sidebar.toggled .nav-item .collapse,
    #wrapper .sidebar.toggled .nav-item .collapsing {
      margin: 0 1rem; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner,
    #wrapper .sidebar.toggled .nav-item .collapsing .collapse-inner {
      padding: .5rem 0;
      min-width: 10rem;
      font-size: .85rem;
      margin: 0 0 1rem 0; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner .collapse-header,
    #wrapper .sidebar.toggled .nav-item .collapsing .collapse-inner .collapse-header {
      margin: 0;
      white-space: nowrap;
      padding: .5rem 1.5rem;
      text-transform: uppercase;
      font-weight: 600;
      font-size: .65rem;
      color: #b7b9cc; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner .collapse-item,
    #wrapper .sidebar.toggled .nav-item .collapsing .collapse-inner .collapse-item {
      padding: .5rem 1rem;
      margin: 0 .5rem;
      display: block;
      color: #3a3b45;
      text-decoration: none;
      border-radius: .35rem;
      white-space: nowrap; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner .collapse-item:hover,
    #wrapper .sidebar.toggled .nav-item .collapsing .collapse-inner .collapse-item:hover {
      background-color: #eaecf4;
      transition: all .3s ease-in-out;
      -webkit-transition: all .3s ease-in-out; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner .collapse-item:active,
    #wrapper .sidebar.toggled .nav-item .collapsing .collapse-inner .collapse-item:active {
      background-color: #dddfeb; }
    #wrapper .sidebar.toggled .nav-item .collapse .collapse-inner .collapse-item.active,
    #wrapper .sidebar.toggled .nav-item .collapsing .collapse-inner .collapse-item.active {
      color: #3f51b5;
      font-weight: 800; }
    #wrapper .sidebar.toggled .sidebar-brand {
      height: 4.375rem;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 600;
      padding: 1.5rem 1rem;
      text-align: center;
      letter-spacing: .05rem;
      z-index: 1; }
    #wrapper .sidebar.toggled hr.sidebar-divider {
      margin: 1rem 0 1rem 0; }
    #wrapper .sidebar.toggled .sidebar-heading {
      display: block;
      text-align: left;
      padding: 0 1rem;
      font-weight: 600;
      font-size: .75rem;
      letter-spacing: .13rem; }
    #wrapper .sidebar.toggled .sidebar-brand .sidebar-brand-text {
      display: inline; }
    #wrapper .sidebar {
      width: 0 !important;
      overflow: hidden;
      transition: all .3s ease;
      -webkit-transition: all .3s ease; }
    #wrapper .sidebar .sidebar-brand {
      overflow: hidden; }
    #wrapper .sidebar .sidebar-brand .sidebar-brand-text {
      overflow: hidden; }
    #wrapper .sidebar .nav-item {
      overflow: hidden; }
    #wrapper .sidebar .sidebar-brand .sidebar-brand-icon img {
      overflow: hidden; }
    #wrapper .sidebar hr.sidebar-divider {
      margin: 1rem 0 1rem 0; }
    #wrapper .topbar .navbar-search {
      width: 100%; }
    #wrapper .login-form {
      padding: 3rem 2rem !important; } }
  #wrapper .breadcrumb {
    background-color: transparent;
    font-size: 12px;
    font-weight: 700; }
  #wrapper .sidebar-light .sidebar-brand {
    color: #fafafa;
    background-color: #4c60da; }
  #wrapper .sidebar-light hr.sidebar-divider {
    border-top: 1px solid #eaecf4; }
  #wrapper .sidebar-light .sidebar-heading {
    color: #b7b9cc; }
  #wrapper .sidebar-light .nav-item .nav-link {
    color: #858796; }
  #wrapper .sidebar-light .nav-item .nav-link i {
    color: #9e9e9e; }
  #wrapper .sidebar-light .nav-item .nav-link:active,
  #wrapper .sidebar-light .nav-item .nav-link:focus,
  #wrapper .sidebar-light .nav-item .nav-link:hover {
    color: #6e707e;
    background-color: #eee; }
  #wrapper .sidebar-light .nav-item .nav-link:active i,
  #wrapper .sidebar-light .nav-item .nav-link:focus i,
  #wrapper .sidebar-light .nav-item .nav-link:hover i {
    color: #6e707e; }
  #wrapper .sidebar-light .nav-item .nav-link[data-toggle=collapse]::after {
    color: #b7b9cc; }
  #wrapper .sidebar-light .nav-item.active .nav-link {
    color: #5b5c67;
    /*background-color: #6777ef;*/ }
  #wrapper .sidebar-light .nav-item.active .nav-link i {
    color: #6e707e; }
  #wrapper .sidebar-light #sidebarToggle {
    background-color: #eaecf4; }
  #wrapper .sidebar-light #sidebarToggle::after {
    color: #b7b9cc; }
  #wrapper .sidebar-light #sidebarToggle:hover {
    background-color: #dddfeb; }
  #wrapper .sidebar-dark .sidebar-brand {
    color: #fff; }
  #wrapper .sidebar-dark hr.sidebar-divider {
    border-top: 1px solid rgba(255, 255, 255, 0.15); }
  #wrapper .sidebar-dark .sidebar-heading {
    color: rgba(255, 255, 255, 0.4); }
  #wrapper .sidebar-dark .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.8); }
  #wrapper .sidebar-dark .nav-item .nav-link i {
    color: #d1d3e2; }
  #wrapper .sidebar-dark .nav-item .nav-link:active,
  #wrapper .sidebar-dark .nav-item .nav-link:focus,
  #wrapper .sidebar-dark .nav-item .nav-link:hover {
    color: #fff; }
  #wrapper .sidebar-dark .nav-item .nav-link:active i,
  #wrapper .sidebar-dark .nav-item .nav-link:focus i,
  #wrapper .sidebar-dark .nav-item .nav-link:hover i {
    color: #fff; }
  #wrapper .sidebar-dark .nav-item .nav-link[data-toggle=collapse]::after {
    color: rgba(255, 255, 255, 0.5); }
  #wrapper .sidebar-dark .nav-item.active .nav-link {
    color: #fff; }
  #wrapper .sidebar-dark .nav-item.active .nav-link i {
    color: #fff; }
  #wrapper .sidebar-dark #sidebarToggle {
    background-color: rgba(255, 255, 255, 0.2); }
  #wrapper .sidebar-dark #sidebarToggle::after {
    color: rgba(255, 255, 255, 0.5); }
  #wrapper .sidebar-dark #sidebarToggle:hover {
    background-color: rgba(255, 255, 255, 0.25); }
  #wrapper .sidebar-dark.toggled #sidebarToggle::after {
    color: rgba(255, 255, 255, 0.5); }
  #wrapper .btn-icon-split {
    padding: 0;
    overflow: hidden;
    /*display: -webkit-inline-box;*/
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: center;
            justify-content: center; }
  #wrapper .btn-icon-split .icon {
    background: rgba(0, 0, 0, 0.15);
    display: inline-block;
    padding: .375rem .75rem; }
  #wrapper .btn-icon-split .text {
    display: inline-block;
    padding: .375rem .75rem; }
  #wrapper .btn-group-sm > .btn-icon-split.btn .icon,
  #wrapper .btn-icon-split.btn-sm .icon {
    padding: .25rem .5rem; }
  #wrapper .btn-group-sm > .btn-icon-split.btn .text,
  #wrapper .btn-icon-split.btn-sm .text {
    padding: .25rem .5rem; }
  #wrapper .btn-group-lg > .btn-icon-split.btn .icon,
  #wrapper .btn-icon-split.btn-lg .icon {
    padding: .5rem 1rem; }
  #wrapper .btn-group-lg > .btn-icon-split.btn .text,
  #wrapper .btn-icon-split.btn-lg .text {
    padding: .5rem 1rem; }
  #wrapper .card .card-header .dropdown {
    line-height: 1; }
  #wrapper .card .card-header .dropdown .dropdown-menu {
    line-height: 1.5; }
  #wrapper .card .card-header[data-toggle=collapse] {
    text-decoration: none;
    position: relative;
    padding: .75rem 3.25rem .75rem 1.25rem; }
  #wrapper .card .card-header[data-toggle=collapse]::after {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 1.725rem;
    line-height: 51px;
    font-weight: 900;
    content: '\f107'; }
  #wrapper .card .card-header[data-toggle=collapse].collapsed {
    border-radius: .35rem; }
  #wrapper .card .card-header[data-toggle=collapse].collapsed::after {
    content: '\f105'; }
  #wrapper .chart-area {
    position: relative;
    height: 10rem;
    width: 100%; }
  @media (min-width: 768px) {
    #wrapper .chart-area {
      height: 20rem; } }
  #wrapper .chart-bar {
    position: relative;
    height: 10rem;
    width: 100%; }
  @media (min-width: 768px) {
    #wrapper .chart-bar {
      height: 20rem; } }
  #wrapper .chart-pie {
    position: relative;
    height: 15rem;
    width: 100%; }
  @media (min-width: 768px) {
    #wrapper .chart-pie {
      height: calc(20rem - 43px) !important; } }
  #wrapper .bg-login-image {
    background-position: center;
    background-size: cover; }
  #wrapper .bg-register-image {
    background-position: center;
    background-size: cover; }
  #wrapper .bg-password-image {
    background-position: center;
    background-size: cover; }
  #wrapper .btn-google {
    color: #434334;
    background-color: #fff;
    border-color: #bdbdbd;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
    border-radius: .25rem; }
  #wrapper .btn-google:hover {
    color: #434343;
    background-color: #f5f5f5;
    border-color: #434343; }
  #wrapper .btn-google.focus,
  #wrapper .btn-google:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  #wrapper .btn-google.disabled,
  #wrapper .btn-google:disabled {
    color: #434343;
    background-color: #eee;
    border-color: #434334; }
  #wrapper .btn-google:not(:disabled):not(.disabled).active,
  #wrapper .btn-google:not(:disabled):not(.disabled):active,
  #wrapper .show > .btn-google.dropdown-toggle {
    color: #434334;
    background-color: #eee;
    border-color: #434343; }
  #wrapper .btn-google:not(:disabled):not(.disabled).active:focus,
  #wrapper .btn-google:not(:disabled):not(.disabled):active:focus,
  #wrapper .show > .btn-google.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  #wrapper .btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: #fff;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
    border-radius: .25rem; }
  #wrapper .btn-facebook:hover {
    color: #fff;
    background-color: #30497c;
    border-color: #e6e6e6; }
  #wrapper .btn-facebook.focus,
  #wrapper .btn-facebook:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  #wrapper .btn-facebook.disabled,
  #wrapper .btn-facebook:disabled {
    color: #fff;
    background-color: #3b5998;
    border-color: #fff; }
  #wrapper .btn-facebook:not(:disabled):not(.disabled).active,
  #wrapper .btn-facebook:not(:disabled):not(.disabled):active,
  #wrapper .show > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4373;
    border-color: #dfdfdf; }
  #wrapper .btn-facebook:not(:disabled):not(.disabled).active:focus,
  #wrapper .btn-facebook:not(:disabled):not(.disabled):active:focus,
  #wrapper .show > .btn-facebook.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  #wrapper .error {
    color: #5a5c69;
    font-size: 7rem;
    position: relative;
    line-height: 1;
    width: 12.5rem; }

@-webkit-keyframes noise-anim {
  0% {
    clip: rect(31px, 9999px, 91px, 0); }
  5% {
    clip: rect(70px, 9999px, 29px, 0); }
  10% {
    clip: rect(3px, 9999px, 56px, 0); }
  15% {
    clip: rect(7px, 9999px, 78px, 0); }
  20% {
    clip: rect(97px, 9999px, 4px, 0); }
  25% {
    clip: rect(24px, 9999px, 60px, 0); }
  30% {
    clip: rect(80px, 9999px, 41px, 0); }
  35% {
    clip: rect(78px, 9999px, 14px, 0); }
  40% {
    clip: rect(20px, 9999px, 23px, 0); }
  45% {
    clip: rect(70px, 9999px, 61px, 0); }
  50% {
    clip: rect(65px, 9999px, 89px, 0); }
  55% {
    clip: rect(74px, 9999px, 25px, 0); }
  60% {
    clip: rect(76px, 9999px, 84px, 0); }
  65% {
    clip: rect(56px, 9999px, 10px, 0); }
  70% {
    clip: rect(85px, 9999px, 58px, 0); }
  75% {
    clip: rect(46px, 9999px, 71px, 0); }
  80% {
    clip: rect(6px, 9999px, 64px, 0); }
  85% {
    clip: rect(20px, 9999px, 84px, 0); }
  90% {
    clip: rect(57px, 9999px, 26px, 0); }
  95% {
    clip: rect(36px, 9999px, 92px, 0); }
  100% {
    clip: rect(12px, 9999px, 53px, 0); } }

@keyframes noise-anim {
  0% {
    clip: rect(31px, 9999px, 91px, 0); }
  5% {
    clip: rect(70px, 9999px, 29px, 0); }
  10% {
    clip: rect(3px, 9999px, 56px, 0); }
  15% {
    clip: rect(7px, 9999px, 78px, 0); }
  20% {
    clip: rect(97px, 9999px, 4px, 0); }
  25% {
    clip: rect(24px, 9999px, 60px, 0); }
  30% {
    clip: rect(80px, 9999px, 41px, 0); }
  35% {
    clip: rect(78px, 9999px, 14px, 0); }
  40% {
    clip: rect(20px, 9999px, 23px, 0); }
  45% {
    clip: rect(70px, 9999px, 61px, 0); }
  50% {
    clip: rect(65px, 9999px, 89px, 0); }
  55% {
    clip: rect(74px, 9999px, 25px, 0); }
  60% {
    clip: rect(76px, 9999px, 84px, 0); }
  65% {
    clip: rect(56px, 9999px, 10px, 0); }
  70% {
    clip: rect(85px, 9999px, 58px, 0); }
  75% {
    clip: rect(46px, 9999px, 71px, 0); }
  80% {
    clip: rect(6px, 9999px, 64px, 0); }
  85% {
    clip: rect(20px, 9999px, 84px, 0); }
  90% {
    clip: rect(57px, 9999px, 26px, 0); }
  95% {
    clip: rect(36px, 9999px, 92px, 0); }
  100% {
    clip: rect(12px, 9999px, 53px, 0); } }
  #wrapper .error:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 #e74a3b;
    top: 0;
    color: #5a5c69;
    background: #f8f9fc;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse; }

@-webkit-keyframes noise-anim-2 {
  0% {
    clip: rect(68px, 9999px, 1px, 0); }
  5% {
    clip: rect(44px, 9999px, 53px, 0); }
  10% {
    clip: rect(72px, 9999px, 43px, 0); }
  15% {
    clip: rect(18px, 9999px, 16px, 0); }
  20% {
    clip: rect(6px, 9999px, 72px, 0); }
  25% {
    clip: rect(18px, 9999px, 16px, 0); }
  30% {
    clip: rect(93px, 9999px, 46px, 0); }
  35% {
    clip: rect(100px, 9999px, 33px, 0); }
  40% {
    clip: rect(27px, 9999px, 29px, 0); }
  45% {
    clip: rect(5px, 9999px, 64px, 0); }
  50% {
    clip: rect(40px, 9999px, 65px, 0); }
  55% {
    clip: rect(72px, 9999px, 49px, 0); }
  60% {
    clip: rect(44px, 9999px, 39px, 0); }
  65% {
    clip: rect(84px, 9999px, 45px, 0); }
  70% {
    clip: rect(99px, 9999px, 56px, 0); }
  75% {
    clip: rect(87px, 9999px, 20px, 0); }
  80% {
    clip: rect(52px, 9999px, 8px, 0); }
  85% {
    clip: rect(64px, 9999px, 22px, 0); }
  90% {
    clip: rect(51px, 9999px, 69px, 0); }
  95% {
    clip: rect(2px, 9999px, 11px, 0); }
  100% {
    clip: rect(19px, 9999px, 40px, 0); } }

@keyframes noise-anim-2 {
  0% {
    clip: rect(68px, 9999px, 1px, 0); }
  5% {
    clip: rect(44px, 9999px, 53px, 0); }
  10% {
    clip: rect(72px, 9999px, 43px, 0); }
  15% {
    clip: rect(18px, 9999px, 16px, 0); }
  20% {
    clip: rect(6px, 9999px, 72px, 0); }
  25% {
    clip: rect(18px, 9999px, 16px, 0); }
  30% {
    clip: rect(93px, 9999px, 46px, 0); }
  35% {
    clip: rect(100px, 9999px, 33px, 0); }
  40% {
    clip: rect(27px, 9999px, 29px, 0); }
  45% {
    clip: rect(5px, 9999px, 64px, 0); }
  50% {
    clip: rect(40px, 9999px, 65px, 0); }
  55% {
    clip: rect(72px, 9999px, 49px, 0); }
  60% {
    clip: rect(44px, 9999px, 39px, 0); }
  65% {
    clip: rect(84px, 9999px, 45px, 0); }
  70% {
    clip: rect(99px, 9999px, 56px, 0); }
  75% {
    clip: rect(87px, 9999px, 20px, 0); }
  80% {
    clip: rect(52px, 9999px, 8px, 0); }
  85% {
    clip: rect(64px, 9999px, 22px, 0); }
  90% {
    clip: rect(51px, 9999px, 69px, 0); }
  95% {
    clip: rect(2px, 9999px, 11px, 0); }
  100% {
    clip: rect(19px, 9999px, 40px, 0); } }
  #wrapper .error:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 #3f51b5;
    top: 0;
    color: #5a5c69;
    background: #f8f9fc;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse; }
  #wrapper footer.sticky-footer {
    padding: 2rem 0;
    /*-ms-flex-negative: 0;*/
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15); }
  #wrapper footer.sticky-footer .copyright {
    line-height: 1;
    font-size: .8rem; }
  #wrapper footer.sticky-footer .copyright a {
    color: #455a64; }
  #wrapper footer.sticky-footer .copyright a:hover {
    text-decoration: none; }
  #wrapper body.sidebar-toggled footer.sticky-footer {
    width: 100%; }
  #wrapper .bg-blue {
    background-color: #e8eaf6 !important; }
  #wrapper .table-flush td,
  #wrapper .table-flush th {
    border-right: 0;
    border-left: 0; }
  #wrapper .table-flush tbody tr:first-child td,
  #wrapper .table-flush tbody tr:first-child th {
    border-top: 0; }
  #wrapper .table-flush tbody tr:last-child td,
  #wrapper .table-flush tbody tr:last-child th {
    border-bottom: 0; }
  #wrapper .card .table {
    margin-bottom: 0; }
  #wrapper .card .table td,
  #wrapper .card .table th {
    padding-right: 1.5rem;
    padding-left: 1.5rem; }
  #wrapper .customer-message {
    border-bottom: 1px solid #e3e6f0;
    padding-bottom: 10px;
    padding-top: 10px; }
  #wrapper .customer-message:hover {
    background-color: #f8f9fc; }
  #wrapper .customer-message a .message-title {
    padding-right: 1.3rem;
    padding-left: 1.3rem; }
  #wrapper .customer-message a .message-time {
    padding-right: 1.3rem;
    padding-left: 1.3rem; }
  #wrapper .customer-message a:hover {
    text-decoration: none; }
  #wrapper .btn-outline-light {
    color: #373840; }
  #wrapper .card {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important; }
  #wrapper .input-group-append .btn {
    box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important; }
  #wrapper .input-group-append .btn:hover {
    box-shadow: 0 0.125rem 0.3rem 0 rgba(58, 59, 69, 0.4) !important; }
  #wrapper .form-control:focus {
    border: 1px solid #6777ef; }
  #wrapper .input-group-prepend span {
    box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }

.custom-navbar-slider {
  position: relative; }
  .custom-navbar-slider .custom-navbar-slider-user-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #eeeeee;
    padding-top: 20px; }

@charset "UTF-8";
/*공통*/
* {
  box-sizing: border-box !important; }

.min-vh-100 {
  min-height: calc(100vh - 300px) !important; }

h1, h2, h3, h4, h5, h6 {
  text-overflow: ellipsis; }

input:focus, textarea:focus, select:focus {
  outline: none !important; }

.underline {
  text-decoration: underline; }

.rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.rounded-right-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rounded-bottom-0 {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.rounded-left-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.nav_margin_container {
  padding-top: 120px; }

.loading {
  -webkit-transition-duration: 1s;
  transition: 1s linear infinite;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: loadingSpin 3s infinite;
          animation: loadingSpin 3s infinite; }

@-webkit-keyframes loadingSpin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes loadingSpin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.layer {
  z-index: 3 !important; }

header#plain.sticky {
  padding-bottom: 10px; }

.modal_background {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8); }

.modal_body {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 600px;
  height: 500px;
  background: #ffffff; }

.container {
  max-width: 1600px; }

.cursor_pointer {
  cursor: pointer; }
  .cursor_pointer_bg:hover {
    background: rgba(0, 69, 114, 0.1) !important; }
  .cursor_pointer_bg_light:hover {
    background: rgba(0, 0, 0, 0.1) !important; }

.bg-buddible {
  background: #004572 !important; }

.border-buddible {
  border-color: #004572 !important; }

.text-buddible {
  color: #004572 !important; }

.overflow_scroll_x {
  overflow-x: scroll; }

.overflow_scroll_y {
  overflow-y: scroll; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  white-space: break-spaces;
  word-break: keep-all; }

.ellipsis_1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; }

.ellipsis_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; }

.ellipsis_3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; }

.ellipsis_4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; }

.ellipsis_5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5; }

.font-size-09 {
  font-size: 0.9em !important; }

.font-size-08 {
  font-size: 0.8em !important; }

.font-size-07 {
  font-size: 0.7em !important; }

.font-size-06 {
  font-size: 0.6em !important; }

.font-size-05 {
  font-size: 0.5em !important; }

.font-size-04 {
  font-size: 0.4em !important; }

.font-size-03 {
  font-size: 0.3em !important; }

.font-size-02 {
  font-size: 0.2em !important; }

.font-size-01 {
  font-size: 0.1em !important; }

.rating > i {
  color: #F90; }

/*메인*/
#plain {
  border-bottom: 1px solid #cccccc; }

.categories_grid_img {
  min-height: 250px;
  transition: all 0.4s;
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }
  @media screen and (max-width: 600px) {
    .categories_grid_img {
      min-height: 150px; } }
  .categories_grid_img:hover {
    -webkit-transform: scale(1);
            transform: scale(1); }

.owl_carousel_img {
  min-height: 225px; }

.owl-item .item {
  color: #111 !important; }

.tour_container .tour_desc {
  padding: 0 15px 10px 15px;
  position: relative; }

.tour_container a {
  color: #111; }

#bestGuide .owl-nav {
  margin-top: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 15px;
  z-index: 1; }
  #bestGuide .owl-nav > button {
    font-size: 4em;
    color: rgba(255, 255, 255, 0.5); }
    #bestGuide .owl-nav > button:hover {
      color: rgba(0, 0, 0, 0.5);
      background: transparent; }

.owl-nav-custom-center .owl-nav {
  margin-top: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0;
  z-index: 1; }
  .owl-nav-custom-center .owl-nav button {
    font-size: 3em !important;
    color: rgba(255, 255, 255, 0.5) !important; }
    .owl-nav-custom-center .owl-nav button:hover {
      color: rgba(0, 0, 0, 0.5) !important;
      background: transparent !important; }

#guideDetail .owl-nav {
  background: powderblue; }

.custom_date_picker .react-datepicker-wrapper {
  width: 100%;
  height: 100%; }

.custom_date_picker .routeDetailAndPaymentDatePickerBtn {
  cursor: pointer;
  height: 100% !important;
  padding: 20px !important; }

.custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper {
  position: absolute !important;
  left: auto !important;
  top: 0 !important;
  right: 0 !important;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0) !important;
          transform: translate3d(0, 0, 0) !important;
  width: auto;
  height: auto;
  background: #ffffff;
  padding: 20px 40px;
  border-radius: 6px;
  will-change: unset;
  margin: 0;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2); }
  .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker {
    width: auto;
    border: none;
    background: transparent !important; }
    .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation {
      top: 15px; }
    .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container {
      width: calc(50% - 5);
      height: 100%; }
      .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container:nth-of-type(1) {
        margin-right: 10px; }
      .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header {
        background: transparent;
        border: none; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
          font-size: 1.5em; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names {
          font-size: 1.2em; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-name {
          display: inline-block;
          width: 2.5rem;
          line-height: 2.5rem;
          text-align: center;
          margin: 0; }
      .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month {
        font-size: 1em; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day {
          display: inline-block;
          width: 2.5rem;
          line-height: 2.5rem;
          text-align: center;
          margin: 0; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--disabled {
          background: #f5f6f7;
          border-radius: 0; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--today {
          background: transparent;
          color: #222222;
          font-weight: 500; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--in-selecting-range {
          background: transparent;
          color: #222222; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--selected {
          color: #ffffff;
          background: #aaaaaa; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--in-selecting-range-start {
          color: #ffffff;
          background: #aaaaaa; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--in-selecting-range-end {
          background: #aaaaaa; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--in-range {
          background: #cccccc;
          border-radius: 0; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--range-start {
          border: none;
          color: #ffffff;
          background: #666666; }
        .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--range-end {
          color: #ffffff;
          background: #666666; }
  .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .routeDetailAndPaymentDatePickerModalHeader {
    text-align: left;
    width: 600px;
    height: 80px; }
  .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .routeDetailAndPaymentDatePickerModalFooter {
    text-align: left; }
    .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .routeDetailAndPaymentDatePickerModalFooter .routeDetailOpenService {
      display: inline-block; }
      .custom_date_picker .react-datepicker__tab-loop .react-datepicker-popper .routeDetailAndPaymentDatePickerModalFooter .routeDetailOpenService > li {
        padding: 5px 10px;
        margin-right: 5px;
        display: inline-block;
        border: 1px solid #cccccc;
        border-radius: 50px;
        background: #eeeeee; }

/*지도 검색*/
.mapWrapBox {
  height: 100vh;
  padding-top: 9px;
  margin-top: -90px;
  position: -webkit-sticky;
  position: sticky;
  top: 0; }

.MapWrap {
  width: 100%;
  height: 100%;
  font-size: 20px;
  text-align: center;
  position: relative; }

.MapWrap > .sbMapbox {
  height: 100%; }

.sbMapbox .googleMap {
  width: 100%;
  height: 100vh; }
  .sbMapbox .googleMap .googleMap_map {
    width: 100%;
    height: 100%; }

.map_content {
  color: #111; }

.map_content_wrap {
  z-index: 10;
  width: 245px; }
  .map_content_wrap > a {
    width: 100%; }
    .map_content_wrap > a > .map_content_img {
      min-height: 140px; }

.gm-style .gm-style-iw-c {
  padding: 0 !important; }

.gm-style-iw-d {
  padding: 0.5rem !important;
  overflow: unset !important; }

.searchByMapMap_Title {
  margin-right: 18px; }

.gm-ui-hover-effect {
  top: 8px !important;
  right: 8px !important;
  width: calc(1rem + 2px) !important;
  height: calc(1rem + 2px) !important;
  border-radius: 8px !important;
  background: rgba(0, 0, 0, 0.6) !important;
  color: #fff !important;
  padding: 2px !important; }

.gm-ui-hover-effect > span {
  margin: 0 !important;
  background-color: #fff !important; }

.styled-select select {
  width: 100% !important; }

.styled-select-common {
  border-right: 0;
  border-left: 0; }

.custom_owl-loaded {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse; }

.custom_owl-stage-outer {
  bottom: 0; }

.custom_owl-nav {
  text-align: right;
  position: relative;
  top: 0;
  margin-bottom: 20px; }

.moveBtn {
  background: #ffffff;
  border: 1px solid #dddddd; }

.moveBtn, .moveBtn span {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  border-radius: 15px;
  margin: 0;
  padding: 0; }

.moveBtn:hover {
  opacity: 0.5; }

#tourListWithinHourItem {
  background: rgba(0, 0, 0, 0.2); }

#tourListWithinHourItem:hover {
  background: rgba(0, 0, 0, 0.4); }

.community-main-p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.img_container_community {
  position: relative;
  overflow: hidden;
  border-radius: 5px; }

#community_main_img {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transition: all 0.3s ease-in-out; }

#community_main_img:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2); }

.guideDetailImg {
  transition: 0.2s; }

.guideDetailImg:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  opacity: 0.8; }

.guideDetailBigImg .guideDetailBigImgNav {
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center; }

.guideDetailBigImg .moveBtn {
  color: #eeeeee;
  background: transparent;
  border: unset; }
  .guideDetailBigImg .moveBtn span {
    font-size: 50pt; }

.card-header:hover {
  background: rgba(0, 0, 0, 0.1); }

.community-detail-content img {
  cursor: auto !important; }

.hoverOpacity:hover {
  opacity: 0.8; }

.modal-body {
  overflow-y: scroll; }

.max-vh-10 {
  max-height: 10vh; }

.min-vh-10 {
  min-height: 10vh; }

.max-vw-10 {
  max-width: 10vh; }

.max-vw-10 {
  min-width: 10vh; }

.max-vh-20 {
  max-height: 20vh; }

.min-vh-20 {
  min-height: 20vh; }

.max-vw-20 {
  max-width: 20vh; }

.max-vw-20 {
  min-width: 20vh; }

.max-vh-30 {
  max-height: 30vh; }

.min-vh-30 {
  min-height: 30vh; }

.max-vw-30 {
  max-width: 30vh; }

.max-vw-30 {
  min-width: 30vh; }

.max-vh-40 {
  max-height: 40vh; }

.min-vh-40 {
  min-height: 40vh; }

.max-vw-40 {
  max-width: 40vh; }

.max-vw-40 {
  min-width: 40vh; }

.max-vh-50 {
  max-height: 50vh; }

.min-vh-50 {
  min-height: 50vh; }

.max-vw-50 {
  max-width: 50vh; }

.max-vw-50 {
  min-width: 50vh; }

.max-vh-60 {
  max-height: 60vh; }

.min-vh-60 {
  min-height: 60vh; }

.max-vw-60 {
  max-width: 60vh; }

.max-vw-60 {
  min-width: 60vh; }

.max-vh-70 {
  max-height: 70vh; }

.min-vh-70 {
  min-height: 70vh; }

.max-vw-70 {
  max-width: 70vh; }

.max-vw-70 {
  min-width: 70vh; }

.max-vh-80 {
  max-height: 80vh; }

.min-vh-80 {
  min-height: 80vh; }

.max-vw-80 {
  max-width: 80vh; }

.max-vw-80 {
  min-width: 80vh; }

.max-vh-90 {
  max-height: 90vh; }

.min-vh-90 {
  min-height: 90vh; }

.max-vw-90 {
  max-width: 90vh; }

.max-vw-90 {
  min-width: 90vh; }

.max-vh-100 {
  max-height: 100vh; }

.min-vh-100 {
  min-height: 100vh; }

.max-vw-100 {
  max-width: 100vh; }

.max-vw-100 {
  min-width: 100vh; }

.min-width-10 {
  min-width: 10px; }

.min-height-10 {
  min-height: 10px; }

.min-width-20 {
  min-width: 20px; }

.min-height-20 {
  min-height: 20px; }

.min-width-30 {
  min-width: 30px; }

.min-height-30 {
  min-height: 30px; }

.min-width-40 {
  min-width: 40px; }

.min-height-40 {
  min-height: 40px; }

.min-width-50 {
  min-width: 50px; }

.min-height-50 {
  min-height: 50px; }

.min-width-60 {
  min-width: 60px; }

.min-height-60 {
  min-height: 60px; }

.min-width-70 {
  min-width: 70px; }

.min-height-70 {
  min-height: 70px; }

.min-width-80 {
  min-width: 80px; }

.min-height-80 {
  min-height: 80px; }

.min-width-90 {
  min-width: 90px; }

.min-height-90 {
  min-height: 90px; }

.min-width-100 {
  min-width: 100px; }

.min-height-100 {
  min-height: 100px; }

.min-width-110 {
  min-width: 110px; }

.min-height-110 {
  min-height: 110px; }

.min-width-120 {
  min-width: 120px; }

.min-height-120 {
  min-height: 120px; }

.min-width-130 {
  min-width: 130px; }

.min-height-130 {
  min-height: 130px; }

.min-width-140 {
  min-width: 140px; }

.min-height-140 {
  min-height: 140px; }

.min-width-150 {
  min-width: 150px; }

.min-height-150 {
  min-height: 150px; }

.min-width-160 {
  min-width: 160px; }

.min-height-160 {
  min-height: 160px; }

.min-width-170 {
  min-width: 170px; }

.min-height-170 {
  min-height: 170px; }

.min-width-180 {
  min-width: 180px; }

.min-height-180 {
  min-height: 180px; }

.min-width-190 {
  min-width: 190px; }

.min-height-190 {
  min-height: 190px; }

.min-width-200 {
  min-width: 200px; }

.min-height-200 {
  min-height: 200px; }

.min-width-210 {
  min-width: 210px; }

.min-height-210 {
  min-height: 210px; }

.min-width-220 {
  min-width: 220px; }

.min-height-220 {
  min-height: 220px; }

.min-width-230 {
  min-width: 230px; }

.min-height-230 {
  min-height: 230px; }

.min-width-240 {
  min-width: 240px; }

.min-height-240 {
  min-height: 240px; }

.min-width-250 {
  min-width: 250px; }

.min-height-250 {
  min-height: 250px; }

.min-width-260 {
  min-width: 260px; }

.min-height-260 {
  min-height: 260px; }

.min-width-270 {
  min-width: 270px; }

.min-height-270 {
  min-height: 270px; }

.min-width-280 {
  min-width: 280px; }

.min-height-280 {
  min-height: 280px; }

.min-width-290 {
  min-width: 290px; }

.min-height-290 {
  min-height: 290px; }

.min-width-300 {
  min-width: 300px; }

.min-height-300 {
  min-height: 300px; }

.min-width-310 {
  min-width: 310px; }

.min-height-310 {
  min-height: 310px; }

.min-width-320 {
  min-width: 320px; }

.min-height-320 {
  min-height: 320px; }

.min-width-330 {
  min-width: 330px; }

.min-height-330 {
  min-height: 330px; }

.min-width-340 {
  min-width: 340px; }

.min-height-340 {
  min-height: 340px; }

.min-width-350 {
  min-width: 350px; }

.min-height-350 {
  min-height: 350px; }

.min-width-360 {
  min-width: 360px; }

.min-height-360 {
  min-height: 360px; }

.min-width-370 {
  min-width: 370px; }

.min-height-370 {
  min-height: 370px; }

.min-width-380 {
  min-width: 380px; }

.min-height-380 {
  min-height: 380px; }

.min-width-390 {
  min-width: 390px; }

.min-height-390 {
  min-height: 390px; }

.min-width-400 {
  min-width: 400px; }

.min-height-400 {
  min-height: 400px; }

.min-width-410 {
  min-width: 410px; }

.min-height-410 {
  min-height: 410px; }

.min-width-420 {
  min-width: 420px; }

.min-height-420 {
  min-height: 420px; }

.min-width-430 {
  min-width: 430px; }

.min-height-430 {
  min-height: 430px; }

.min-width-440 {
  min-width: 440px; }

.min-height-440 {
  min-height: 440px; }

.min-width-450 {
  min-width: 450px; }

.min-height-450 {
  min-height: 450px; }

.min-width-460 {
  min-width: 460px; }

.min-height-460 {
  min-height: 460px; }

.min-width-470 {
  min-width: 470px; }

.min-height-470 {
  min-height: 470px; }

.min-width-480 {
  min-width: 480px; }

.min-height-480 {
  min-height: 480px; }

.min-width-490 {
  min-width: 490px; }

.min-height-490 {
  min-height: 490px; }

.min-width-500 {
  min-width: 500px; }

.min-height-500 {
  min-height: 500px; }

.min-width-510 {
  min-width: 510px; }

.min-height-510 {
  min-height: 510px; }

.min-width-520 {
  min-width: 520px; }

.min-height-520 {
  min-height: 520px; }

.min-width-530 {
  min-width: 530px; }

.min-height-530 {
  min-height: 530px; }

.min-width-540 {
  min-width: 540px; }

.min-height-540 {
  min-height: 540px; }

.min-width-550 {
  min-width: 550px; }

.min-height-550 {
  min-height: 550px; }

.min-width-560 {
  min-width: 560px; }

.min-height-560 {
  min-height: 560px; }

.min-width-570 {
  min-width: 570px; }

.min-height-570 {
  min-height: 570px; }

.min-width-580 {
  min-width: 580px; }

.min-height-580 {
  min-height: 580px; }

.min-width-590 {
  min-width: 590px; }

.min-height-590 {
  min-height: 590px; }

.min-width-600 {
  min-width: 600px; }

.min-height-600 {
  min-height: 600px; }

.min-width-610 {
  min-width: 610px; }

.min-height-610 {
  min-height: 610px; }

.min-width-620 {
  min-width: 620px; }

.min-height-620 {
  min-height: 620px; }

.min-width-630 {
  min-width: 630px; }

.min-height-630 {
  min-height: 630px; }

.min-width-640 {
  min-width: 640px; }

.min-height-640 {
  min-height: 640px; }

.min-width-650 {
  min-width: 650px; }

.min-height-650 {
  min-height: 650px; }

.min-width-660 {
  min-width: 660px; }

.min-height-660 {
  min-height: 660px; }

.min-width-670 {
  min-width: 670px; }

.min-height-670 {
  min-height: 670px; }

.min-width-680 {
  min-width: 680px; }

.min-height-680 {
  min-height: 680px; }

.min-width-690 {
  min-width: 690px; }

.min-height-690 {
  min-height: 690px; }

.min-width-700 {
  min-width: 700px; }

.min-height-700 {
  min-height: 700px; }

.min-width-710 {
  min-width: 710px; }

.min-height-710 {
  min-height: 710px; }

.min-width-720 {
  min-width: 720px; }

.min-height-720 {
  min-height: 720px; }

.min-width-730 {
  min-width: 730px; }

.min-height-730 {
  min-height: 730px; }

.min-width-740 {
  min-width: 740px; }

.min-height-740 {
  min-height: 740px; }

.min-width-750 {
  min-width: 750px; }

.min-height-750 {
  min-height: 750px; }

.min-width-760 {
  min-width: 760px; }

.min-height-760 {
  min-height: 760px; }

.min-width-770 {
  min-width: 770px; }

.min-height-770 {
  min-height: 770px; }

.min-width-780 {
  min-width: 780px; }

.min-height-780 {
  min-height: 780px; }

.min-width-790 {
  min-width: 790px; }

.min-height-790 {
  min-height: 790px; }

.min-width-800 {
  min-width: 800px; }

.min-height-800 {
  min-height: 800px; }

.min-width-810 {
  min-width: 810px; }

.min-height-810 {
  min-height: 810px; }

.min-width-820 {
  min-width: 820px; }

.min-height-820 {
  min-height: 820px; }

.min-width-830 {
  min-width: 830px; }

.min-height-830 {
  min-height: 830px; }

.min-width-840 {
  min-width: 840px; }

.min-height-840 {
  min-height: 840px; }

.min-width-850 {
  min-width: 850px; }

.min-height-850 {
  min-height: 850px; }

.min-width-860 {
  min-width: 860px; }

.min-height-860 {
  min-height: 860px; }

.min-width-870 {
  min-width: 870px; }

.min-height-870 {
  min-height: 870px; }

.min-width-880 {
  min-width: 880px; }

.min-height-880 {
  min-height: 880px; }

.min-width-890 {
  min-width: 890px; }

.min-height-890 {
  min-height: 890px; }

.min-width-900 {
  min-width: 900px; }

.min-height-900 {
  min-height: 900px; }

.min-width-910 {
  min-width: 910px; }

.min-height-910 {
  min-height: 910px; }

.min-width-920 {
  min-width: 920px; }

.min-height-920 {
  min-height: 920px; }

.min-width-930 {
  min-width: 930px; }

.min-height-930 {
  min-height: 930px; }

.min-width-940 {
  min-width: 940px; }

.min-height-940 {
  min-height: 940px; }

.min-width-950 {
  min-width: 950px; }

.min-height-950 {
  min-height: 950px; }

.min-width-960 {
  min-width: 960px; }

.min-height-960 {
  min-height: 960px; }

.min-width-970 {
  min-width: 970px; }

.min-height-970 {
  min-height: 970px; }

.min-width-980 {
  min-width: 980px; }

.min-height-980 {
  min-height: 980px; }

.min-width-990 {
  min-width: 990px; }

.min-height-990 {
  min-height: 990px; }

.text-shadow-sm {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25); }

.text-shadow-md {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }

.custom-default-dropdown-select .react-dropdown-select-input {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.main .parallax-content-2 .theiaStickySidebar #search_container {
  z-index: revert; }

.ribbon_3 {
  z-index: 2; }

.rounded-xl {
  border-radius: .5rem; }

.rotate-10 {
  -webkit-transform: rotate(10deg) !important;
          transform: rotate(10deg) !important; }

.rotate-20 {
  -webkit-transform: rotate(20deg) !important;
          transform: rotate(20deg) !important; }

.rotate-30 {
  -webkit-transform: rotate(30deg) !important;
          transform: rotate(30deg) !important; }

.rotate-40 {
  -webkit-transform: rotate(40deg) !important;
          transform: rotate(40deg) !important; }

.rotate-50 {
  -webkit-transform: rotate(50deg) !important;
          transform: rotate(50deg) !important; }

.rotate-60 {
  -webkit-transform: rotate(60deg) !important;
          transform: rotate(60deg) !important; }

.rotate-70 {
  -webkit-transform: rotate(70deg) !important;
          transform: rotate(70deg) !important; }

.rotate-80 {
  -webkit-transform: rotate(80deg) !important;
          transform: rotate(80deg) !important; }

.rotate-90 {
  -webkit-transform: rotate(90deg) !important;
          transform: rotate(90deg) !important; }

.rotate-100 {
  -webkit-transform: rotate(100deg) !important;
          transform: rotate(100deg) !important; }

.rotate-110 {
  -webkit-transform: rotate(110deg) !important;
          transform: rotate(110deg) !important; }

.rotate-120 {
  -webkit-transform: rotate(120deg) !important;
          transform: rotate(120deg) !important; }

.rotate-130 {
  -webkit-transform: rotate(130deg) !important;
          transform: rotate(130deg) !important; }

.rotate-140 {
  -webkit-transform: rotate(140deg) !important;
          transform: rotate(140deg) !important; }

.rotate-150 {
  -webkit-transform: rotate(150deg) !important;
          transform: rotate(150deg) !important; }

.rotate-160 {
  -webkit-transform: rotate(160deg) !important;
          transform: rotate(160deg) !important; }

.rotate-170 {
  -webkit-transform: rotate(170deg) !important;
          transform: rotate(170deg) !important; }

.rotate-180 {
  -webkit-transform: rotate(180deg) !important;
          transform: rotate(180deg) !important; }

.rotate-190 {
  -webkit-transform: rotate(190deg) !important;
          transform: rotate(190deg) !important; }

.rotate-200 {
  -webkit-transform: rotate(200deg) !important;
          transform: rotate(200deg) !important; }

.rotate-210 {
  -webkit-transform: rotate(210deg) !important;
          transform: rotate(210deg) !important; }

.rotate-220 {
  -webkit-transform: rotate(220deg) !important;
          transform: rotate(220deg) !important; }

.rotate-230 {
  -webkit-transform: rotate(230deg) !important;
          transform: rotate(230deg) !important; }

.rotate-240 {
  -webkit-transform: rotate(240deg) !important;
          transform: rotate(240deg) !important; }

.rotate-250 {
  -webkit-transform: rotate(250deg) !important;
          transform: rotate(250deg) !important; }

.rotate-260 {
  -webkit-transform: rotate(260deg) !important;
          transform: rotate(260deg) !important; }

.rotate-270 {
  -webkit-transform: rotate(270deg) !important;
          transform: rotate(270deg) !important; }

.rotate-280 {
  -webkit-transform: rotate(280deg) !important;
          transform: rotate(280deg) !important; }

.rotate-290 {
  -webkit-transform: rotate(290deg) !important;
          transform: rotate(290deg) !important; }

.rotate-300 {
  -webkit-transform: rotate(300deg) !important;
          transform: rotate(300deg) !important; }

.rotate-310 {
  -webkit-transform: rotate(310deg) !important;
          transform: rotate(310deg) !important; }

.rotate-320 {
  -webkit-transform: rotate(320deg) !important;
          transform: rotate(320deg) !important; }

.rotate-330 {
  -webkit-transform: rotate(330deg) !important;
          transform: rotate(330deg) !important; }

.rotate-340 {
  -webkit-transform: rotate(340deg) !important;
          transform: rotate(340deg) !important; }

.rotate-350 {
  -webkit-transform: rotate(350deg) !important;
          transform: rotate(350deg) !important; }

.quill-wrap img {
  max-width: 100% !important; }

.css-93ilmd-DropDown {
  top: calc(100% + 3px) !important;
  width: 100% !important;
  box-shadow: none !important; }

.css-4rg3ly-InputComponent {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.main {
  z-index: 3 !important; }

.modal-backdrop {
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 4 !important; }

.modal {
  z-index: 5 !important; }

.modal-content {
  border: none !important; }

.react-datepicker__navigation {
  background: #fff; }

.react-datepicker__navigation--previous {
  content: '&lt'; }

.react-datepicker__navigation--previous {
  content: '&rt'; }

.search-overlay-menu {
  background: rgba(0, 0, 0, 0.6); }
  .search-overlay-menu #search-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 80%;
    max-width: 1000px;
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s; }
    .search-overlay-menu #search-content .react-dropdown-select-content {
      -webkit-align-items: center;
              align-items: center; }
    .search-overlay-menu #search-content .numbers-row > div, .search-overlay-menu #search-content input {
      height: 36px !important; }
  .search-overlay-menu .css-wmw4vi-ReactDropdownSelect {
    padding: 0; }
  .search-overlay-menu .css-1ngods0-InputComponent {
    margin-left: 0; }
  .search-overlay-menu .react-datepicker__tab-loop .react-datepicker-popper {
    top: calc(100%) !important;
    min-width: 330px !important;
    padding: 20px; }
    .search-overlay-menu .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker__day--outside-month {
      color: #004572; }
    .search-overlay-menu .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker__day--disabled {
      color: #aaaaaa; }

.avata_pic {
  object-fit: cover; }

.chat-header {
  height: 60px !important; }

.chat-footer {
  height: 60px !important; }

.chat-list-center {
  height: calc(100vh - 120px) !important; }

.chat-list-end {
  height: calc(100vh - 60px) !important; }

.chat-msg {
  max-width: 75%; }

.chat-msg-sender {
  border-radius: 0.5rem 0.5rem 0 0.5rem;
  background: #dbf4fe; }

.chat-msg-receiver {
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  background: #f8f9fa; }

.square-10 {
  width: 10px !important;
  height: 10px !important; }

.square-20 {
  width: 20px !important;
  height: 20px !important; }

.square-30 {
  width: 30px !important;
  height: 30px !important; }

.square-40 {
  width: 40px !important;
  height: 40px !important; }

.square-50 {
  width: 50px !important;
  height: 50px !important; }

.square-60 {
  width: 60px !important;
  height: 60px !important; }

.square-70 {
  width: 70px !important;
  height: 70px !important; }

.square-80 {
  width: 80px !important;
  height: 80px !important; }

.square-90 {
  width: 90px !important;
  height: 90px !important; }

.square-100 {
  width: 100px !important;
  height: 100px !important; }

.height-10 {
  height: 10px !important; }

.height-20 {
  height: 20px !important; }

.height-30 {
  height: 30px !important; }

.height-40 {
  height: 40px !important; }

.height-50 {
  height: 50px !important; }

.height-60 {
  height: 60px !important; }

.height-70 {
  height: 70px !important; }

.height-80 {
  height: 80px !important; }

.height-90 {
  height: 90px !important; }

.height-100 {
  height: 100px !important; }

.child-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.erp .ql-snow {
  border: none; }

.erp .ql-editor {
  border-top: 1px solid #dee2e6; }

body {
  margin: 0;
  font-family: 'Noto Sans KR', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, button{
  outline: none;
  border: none;
}
@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: 'Noto Sans KR', 'Noto Sans', sans-serif; }
  *::-webkit-scrollbar {
    background: #f7f7f7;
    width: 5px;
    overflow: auto; }
  *::-webkit-scrollbar-thumb {
    background: #cccccc; }

a {
  color: #222222;
  text-decoration: none; }

li {
  text-decoration: none;
  list-style: none; }

body {
  color: #222222; }

h2 {
  font-size: 2.8125em;
  line-height: 1.2em; }

h3 {
  font-size: 1.375em; }

.default_Visual {
  max-width: 100%;
  height: 80vh;
  max-height: 1000px; }

.default_ExplainText {
  line-height: 1.2em;
  color: #4c4c4c; }

.default_Width {
  max-width: 1320px;
  width: 90%;
  margin: 0 auto; }
  @media screen and (min-width: 1800px) {
    .default_Width {
      max-width: 1600px; } }
  @media screen and (min-width: 360px) and (max-width: 700px) {
    .default_Width {
      width: 95%; } }

.default_small_width {
  max-width: 1032px;
  margin: 0 auto;
  width: 90%;
  padding-top: 130px; }
  .default_small_width h2 {
    font-size: 40px; }
  @media screen and (min-width: 360px) and (max-width: 700px) {
    .default_small_width {
      width: 95%; } }

.default_Etc_Width {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding-top: 130px; }
  .default_Etc_Width h2 {
    font-size: 40px; }
  @media screen and (min-width: 360px) and (max-width: 700px) {
    .default_Etc_Width {
      width: 95%; } }

.default_ContentBox {
  border: 1px solid #cccccc;
  border-radius: 6px; }

.default_button {
  text-align: center;
  border: none;
  background: #eeeeee;
  border-radius: 6px;
  padding: 10px 30px;
  cursor: pointer; }
  .default_button_Point {
    text-align: center;
    border: none;
    background: #004572;
    color: #ffffff;
    border-radius: 6px;
    padding: 10px 30px;
    cursor: pointer; }

.default_defualt_Button_Slide {
  width: 32px;
  height: 32px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-left: 5px;
  background-color: #fff;
  outline: none;
  cursor: pointer; }

.default_modal_animation {
  -webkit-animation-name: modalMove;
          animation-name: modalMove;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.default_commonCheckbox input[type="checkbox"] {
  display: none; }

.default_commonCheckbox_agreeLabel {
  cursor: pointer; }

.default_commonCheckbox_agreeBox + .default_commonCheckbox_agreeLabel {
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  margin-left: 5px; }

.default_commonCheckbox_checkBox + .default_commonCheckbox_checkLabel .default_commonCheckbox_checkBoxWrap {
  display: inline-block;
  border: 2px solid #ddd;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer; }
  .default_commonCheckbox_checkBox + .default_commonCheckbox_checkLabel .default_commonCheckbox_checkBoxWrap .fa-check {
    display: none;
    color: #004572; }

.default_commonCheckbox_checkBox:checked + .default_commonCheckbox_checkLabel .default_commonCheckbox_checkBoxWrap .fa-check {
  display: block;
  font-size: 18px;
  position: relative; }

.default_commonCheckbox_checkBox:checked + .default_commonCheckbox_checkLabel .default_commonCheckbox_checkBoxWrap .fa-check:before {
  position: absolute;
  top: -3px;
  left: 0; }

.default_commonRadiobutton input[type="radio"] {
  display: none; }

.default_commonRadiobutton_radioLabel {
  cursor: pointer; }

.default_commonRadiobutton_radioButton + .default_commonRadiobutton_radioLabel {
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  margin-left: 5px; }

.default_commonRadiobutton_radioButton + .default_commonRadiobutton_radioLabel .default_commonRadiobutton_radioButtonWrap {
  display: inline-block;
  border: 2px solid #ddd;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer; }
  .default_commonRadiobutton_radioButton + .default_commonRadiobutton_radioLabel .default_commonRadiobutton_radioButtonWrap .xi-full-moon {
    display: none;
    color: #004572; }

.default_commonRadiobutton_radioButton:checked + .default_commonRadiobutton_radioLabel .default_commonRadiobutton_radioButtonWrap .xi-full-moon {
  display: block;
  font-size: 18px;
  position: relative; }

.default_commonRadiobutton_radioButton:checked + .default_commonRadiobutton_radioLabel .default_commonRadiobutton_radioButtonWrap .xi-full-moon:before {
  position: absolute;
  top: 1px;
  left: 2px; }
  @media screen and (min-width: 360px) and (max-width: 700px) {
    .default_commonRadiobutton_radioButton:checked + .default_commonRadiobutton_radioLabel .default_commonRadiobutton_radioButtonWrap .xi-full-moon:before {
      left: 1px; } }

/* legend,caption,메뉴제목,섹션제목 블라인드 */
legend, caption, .blind {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  width: 1px;
  height: 1px; }

@-webkit-keyframes modalMove {
  from {
    top: 55%;
    opacity: 0; }
  to {
    top: 40%;
    opacity: 1; } }

@keyframes modalMove {
  from {
    top: 55%;
    opacity: 0; }
  to {
    top: 40%;
    opacity: 1; } }

.commonRadiobutton input[type="radio"] {
  display: none; }

.commonRadiobutton label {
  cursor: pointer; }

.commonRadiobutton input[type="radio"] + label {
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  margin-left: 5px; }

.commonRadiobutton input[type="radio"] + label span {
  display: inline-block;
  border: 2px solid #ddd;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer; }
  .commonRadiobutton input[type="radio"] + label span .xi-full-moon {
    display: none;
    color: #004572; }

.commonRadiobutton input[type="radio"]:checked + label span .xi-full-moon {
  display: block;
  font-size: 18px;
  position: relative; }

.commonRadiobutton input[type="radio"]:checked + label span .xi-full-moon:before {
  position: absolute;
  top: 1px;
  left: 2px; }
  @media screen and (min-width: 360px) and (max-width: 700px) {
    .commonRadiobutton input[type="radio"]:checked + label span .xi-full-moon:before {
      left: 1px; } }

.commonCheckbox input[type="checkbox"] {
  display: none; }

.commonCheckbox label {
  cursor: pointer; }

.commonCheckbox input[type="checkbox"] + label {
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  margin-left: 5px; }

.commonCheckbox input[type="checkbox"] + label span {
  display: inline-block;
  border: 2px solid #ddd;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer; }
  .commonCheckbox input[type="checkbox"] + label span .fa-check {
    display: none;
    color: #004572; }

.commonCheckbox input[type="checkbox"]:checked + label span .fa-check {
  display: block;
  font-size: 18px;
  position: relative; }

.commonCheckbox input[type="checkbox"]:checked + label span .fa-check:before {
  position: absolute;
  top: -3px;
  left: 0; }

nav {
  margin: 0 !important; }

.write_editor {
  border: 1px solid #ddd;
  height: 500px;
  overflow: auto;
  padding-left: 10px; }

.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
  padding: 0px 4px !important; }

.bg-buddible {
  background: #004572; }

.rotate-infinite {
  -webkit-animation: rotate 3s linear infinite;
          animation: rotate 3s linear infinite;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%; }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

